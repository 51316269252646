import { Component, ReactElement } from "react";
import { appContext } from "../../../../AppContext";
import { Card, Grid, TextField, Divider, Typography, Box, Skeleton, FormControl } from "@mui/material";
import "../../../../styles/css/dashboard.scss";
import "./cateringOrder.scss";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../../Common/AllsvgIcons";
import customTheme from "../../../../customTheme";
import IbssIconButton from "../../../../Components/Buttons/IconButton/IbssIconButton";
import { RouteComponentProps } from "react-router-dom";
import IbssTimePicker from "../../../../Components/Inputs/TimePicker/IbssTimePicker";
import { DateTime } from "luxon";
import { ICostCodeAllocation } from "../../../../Providers.Api/Tasks/CreateTaskEndpoint";
import moment from "moment";
import { IPropsFromState } from "../../../../redux/Interfaces";
import { ICateringOrder, IMenuItem } from "../../../../Providers.Api/CateringOrders/GetCateringOrderEndpoint";
import { INode } from "../../../../Providers.Api/Models";
import { Modal } from "react-bootstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import IbssHorizontalTabs, { TabsParameters } from "../../../../Components/Layout/Tabs/IbssTabs";
import IbssAccordion, { IAccordion } from "../../../../Components/Miscellaneous/Accordion/IbssAccordion";
import { ICreateCateringOrder } from "../../../../Providers.Api/CateringOrders/CreateCateringOrderEndpoint";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssCheckBox from "../../../../Components/Inputs/CheckBox/IbssCheckBox";
import { Classification, Status } from "../../../../Providers.Api/CateringItems/CateringItemRepository";
import { IGetV2BookingResponse } from "../../../../Providers.Api/Bookings/GetV2BookingEndpoint";
import { ICateringOrderPolicy } from "../../../../Providers.Api/CateringOrderPolicies/CateringOrderPoliciesRepository";
import CostCodesDialog, { CostCodeWithAllocation } from "../../../../Components/Dialogs/CostCodesDialog/CostCodesDialog";
import { StaticContext } from "react-router";
import { CateringMenuStatus, Filter, IMenuItems } from "../../../../Providers.Api/CateringMenus/CateringMenuRepository";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay";
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import { ICateringMenu } from "../../../../Providers.Api/CateringMenus/GetByIdEndpoint";
import ChangeMenuModal, { IDropdownOptions } from "./ChangeMenuModal";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";

export default class EditCateringOrder extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() { return appContext().apiCache; }
    private buildingId: number;
    private isOneLens: boolean;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            originalOrder: new OrderView(),
            order: new OrderView(),
            orderItems: [],
            loading: false,
            currency: '',
            preBookCateringTime: '',
            bookingStart: '',
            bookingEnd: '',
            showConfirmationModal: false,
            floorId: 0,
            numOfAttendees: 0,
            spaces: [],
            showCostCodeModal: false,
            lightModeTheme: this.appState.lightModeTheme,
            costCodes: [],
            menu: {
                menuId: '',
                name: ''
            },
            cateringRestrictions: [],
            selectedRestrictions: [],
            selectedRestrictionsEdit: [],
            showRestrictionsModal: false,
            selectedAllergenRestrictionsFilter: [],
            selectedAllergenRestrictionsFilterEdit: [],
            selectedDietaryRestrictionsFilterEdit: [],
            selectedDietaryRestrictionsFilter: [],
            showFilterModal: false,
            itemOrderPolicies: [],
            skeletonLoad: false,
            bookingId: '',
            showStatusUpdateConfirmation: '',
            resetCateringStatus: false,
            menuDropdownOptions: [],
            showChangeMenuModal: false,
            availableCateringMenus: []

        };
        this.buildingId = parseInt(this.props.match.params.buildingid);
        this.isOneLens = window.location.href.includes('operational-services-catering');
    }

    public async componentDidMount(): Promise<void>
    {
        if (!this.isOneLens) 
        {
            this.pageTitle = this.labels.HubMenumySchedule;
        }
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
        const { match } = this.props;
        this.setState({ skeletonLoad: true });
        const spaces = await this.apiCache.getSpacesByBuilding(this.buildingId);
        const orderData = (match.params.spacecateringId ? await this.apiClient.cateringOrders.getCateringOrder(this.buildingId, match.params.spacecateringId) : null);
        const spaceData = spaces.filter(x => x.Space_Id == (orderData ? orderData.Space_Id : match.params.spaceId));
        const order = (orderData == null ? null : OrderView.fromApiTask(orderData));
        const floorId = orderData?.Node_Id ?? spaces.filter(space => space.Space_Id == match.params.spaceId)[0].Node_Id;
        const bookingId = orderData?.Booking_Id ?? match.params.bookingid;
        let menuId = orderData?.Menu_Items[0]?.Menu_Id ?? null;
        const bookingData = await this.bookingService.get(floorId, bookingId);
        const orderPolicy = await this.apiClient.cateringOrderPolicies.getMany(this.buildingId);
        let cateringMenu = null

        // If a new order is being made get the available menus for the space the order is against
        if (menuId == null)
        {
            try
            {
                const currentUsersRoles = (match.params.spacecateringId ? null : await this.apiClient.roles.getRolesByCurrentUser());
                const currentUsersRoleIds = currentUsersRoles?.map(i => i.id) ?? null;
                const cateringMenus = await this.apiClient.cateringMenus.getMany(
                    this.buildingId,
                    true,
                    true,
                    100,
                    new Filter({
                        status: CateringMenuStatus.active,
                        availableFrom: DateTime.fromISO(bookingData.Booking_Start),
                        availableTo: DateTime.fromISO(bookingData.Booking_End),
                        roleIds: currentUsersRoleIds ?? undefined,
                        spaceId: bookingData.Space_Id
                    }));

                this.setState({
                    availableCateringMenus: cateringMenus.value.map(menu => MenuView.fromApiModel(menu, null, this.isOneLens, bookingData, orderPolicy)),
                    menuDropdownOptions: cateringMenus.value.map(x => ({ label: x.Name, value: x.Menu_Id }))
                });
                cateringMenu = cateringMenus.value[0] ?? null;
            } catch (error)
            {
                cateringMenu = null;
            }
        }

        // If an existing order is being edited then get the menu that order was created against
        if (menuId != null && cateringMenu == null)
        {
            try
            {
                cateringMenu = await this.apiClient.cateringMenus.getById(this.buildingId, menuId, true, true);
            } catch (error)
            {
                cateringMenu = null;
            }
        }

        if (order == null)
        {
            this.setState({ order: new OrderView({ ...this.state.order, cateringServiceTime: bookingData.Booking_Start, cateringClearingTime: bookingData.Booking_End }) });
        }

        if (order != null)
        {
            this.setState({
                bookingId: bookingId,
                order: order,
                originalOrder: order,
                selectedRestrictions: order.cateringOrderRestrictions,
            });
        }

        if (cateringMenu != null)
        {
            const menuView = MenuView.fromApiModel(cateringMenu, order, this.isOneLens, bookingData, orderPolicy)
            this.setState({
                menu: menuView.menu,
                orderItems: menuView.orderItems,
            })
        }

        const cateringRestrictions = (await this.apiClient.cateringRestrictions.getCateringRestrictions(this.buildingId)).Value;
        const filteredCateringRestrictions = cateringRestrictions.filter(x => x.Section == 'Allergen' || x.Section == 'Dietary');

        this.setState({
            floorId: floorId,
            cateringRestrictions: filteredCateringRestrictions.map(x => ({ id: x.Restriction_Id ?? '', section: x.Section, imageURI: x.ImageURI, name: x.Name })),
            bookingStart: bookingData.Booking_Start?.toString(),
            bookingEnd: bookingData.Booking_End?.toString(),
            spaces: spaces
        });

        const availableSpaces = this.localStorage.getNodeData();
        const buildings = availableSpaces.Regions.map(x => x.Buildings);
        let selectedBuilding: INode | null = null;

        for (let i = 0; i < buildings.length; i++)
        {
            const building = buildings[i].filter(building => building.Node_Id === this.buildingId);
            if (building.length > 0)
            {
                selectedBuilding = building[0];
                break;
            }
        }
        if (selectedBuilding == null)
        {
            return;
        }
        this.setState({ currency: selectedBuilding.Cat_Crncy, preBookCateringTime: selectedBuilding.Pre_Book_Cat_Time });

        await this.loadTaskCostCodes();
        this.setState({ skeletonLoad: false });
    };

    private itemQuantityChanged(itemId: string, type: 'remove' | 'add'): void
    {
        const objIndex = this.state.orderItems.findIndex(x => x.menuItemId == itemId);
        let orderItems = this.state.orderItems;

        if (type == 'remove' && orderItems[objIndex].quantity != 0)
        {
            orderItems[objIndex].quantity = orderItems[objIndex].quantity - 1;
        }
        if (type == 'add')
        {
            orderItems[objIndex].quantity = orderItems[objIndex].quantity + 1;
        }
        this.setState({ orderItems: orderItems, resetCateringStatus: true });

    }

    private async updateOrder(): Promise<void>
    {
        const { match } = this.props
        this.setState({ loading: true })
        const activeurl = window.location.href;

        const orderTotal = this.state.orderItems?.map(x => x.unitPrice * x.quantity).reduce((x, y) => { return x + y }, 0).toFixed(2);

        const orderItems = this.state.orderItems
            .filter(item => item.quantity > 0).map(item => { return ({ Id: item.menuItemId, Menu_Id: this.state.menu.menuId, Description: item.description, QuantityOfItems: item.quantity }) });

        const costCodeAllocations = this.state.costCodes
            .map(i => ({ Cost_Code: i.costCode, Cost_Code_Id: i.costCodeId, Allocation: i.allocation }));

        const payload = this.state.order.toApiOrder(this.state.order.spaceId, this.state.bookingId, this.state.floorId, this.state.numOfAttendees, JSON.stringify(orderItems), costCodeAllocations, parseFloat(orderTotal), this.state.selectedRestrictions, this.state.resetCateringStatus ? 'PendingApproval' : this.state.order.cateringStatus);

        await this.apiClient.cateringOrders.editCateringOrder(this.state.floorId, this.state.order.orderId, payload);

        if (this.props.location.state?.originRoute)
        {
            this.props.history.push(this.props.location.state?.originRoute);
        }
        else if (match.params.bookingid !== undefined || activeurl.includes("flex-my-bookings"))
        {
            this.props.history.goBack();
        }
        else
        {
            this.props.history.push("/catering-orders/" + this.buildingId);
        }
    }

    private cancelChanges(): void
    {
        const { match } = this.props
        const activeurl = window.location.href;

        if (this.props.location.state?.originRoute)
        {
            this.props.history.push(this.props.location.state?.originRoute);
        }
        else if (match.params.bookingid !== undefined || activeurl.includes("flex-my-bookings"))
        {
            this.props.history.goBack();
        }
        else
        {
            this.props.history.push("/catering-orders/" + this.buildingId);
        }
    }

    private async create(): Promise<void>
    {
        const { match } = this.props
        this.setState({ loading: true });
        const orderTotal = this.state.orderItems?.map(x => x.unitPrice * x.quantity).reduce((x, y) => { return x + y }, 0).toFixed(2);
        const orderItems = this.state.orderItems
            .filter(item => item.quantity > 0).map(item => { return ({ Id: item.menuItemId, Menu_Id: this.state.menu.menuId, Description: item.description, QuantityOfItems: item.quantity }) });
        const costCodeAllocations = this.state.costCodes
            .map(i => ({ Cost_Code: i.costCode, Cost_Code_Id: i.costCodeId, Allocation: i.allocation }));
        const payload = this.state.order.toApiOrder(match.params.spaceId, match.params.bookingid, this.state.floorId, this.state.numOfAttendees, JSON.stringify(orderItems), costCodeAllocations, parseFloat(orderTotal), this.state.selectedRestrictions, 'PendingApproval');
        await this.apiClient.cateringOrders.createCateringOrder(this.state.floorId, payload)
        // redirect to schedule view if there is any state in the route, else go back to flex-my-bookings
        if (this.props.location.state?.originRoute)
        {
            this.props.history.push(this.props.location.state?.originRoute);
        }
        else
        {
            this.props.history.push(`/flex-my-bookings/${this.buildingId}/mybooking/${match.params.bookingid}/${match.params.spaceId}`);
        }
    }

    private spaceNamesById: (Map<string, string> | null) = null;
    private getSpaceNameById(spaceId: string): string
    {
        this.spaceNamesById = new Map(this.state.spaces.map(i => [i.Space_Id, i.Space_Name]));
        return this.spaceNamesById.get(spaceId) ?? "";
    }

    private handleCostCodeModal(): void
    {
        this.setState((prevState) => ({
            showCostCodeModal: !prevState.showCostCodeModal
        }));
    }

    private updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState((prevState) =>
        {
            return {
                ...prevState,
                costCodes: updatedCostCodes,
                order: new OrderView({
                    ...prevState.order,
                    costCodeAllocation: updatedCostCodes.map(i => ({ costCode: i.costCode, costCodeId: i.costCodeId, allocation: i.allocation, costCodeDescription: i.costCodeDescription })),
                }),
            }
        });
    }

    private async loadTaskCostCodes(): Promise<void>
    {
        const taskCostCodes: ICostAllocation[] = this.state.order.costCodeAllocation ?? [];

        this.setState({
            costCodes: taskCostCodes, //costs codes associated with task, which has to be displayed on this component.
        });
    }

    public handleChangeCateringServiceTime(date: Date | null): void
    {
        if (date !== null)
        {
            const isoDate = DateTime.fromJSDate(date).toISO();
            this.setState({ order: new OrderView({ ...this.state.order, cateringServiceTime: isoDate ?? '' }), resetCateringStatus: true });
        }
    }

    public handleChangeCateringClearingTime(date: Date | null): void
    {
        if (date !== null)
        {
            const isoDate = DateTime.fromJSDate(date).toISO();
            this.setState({ order: new OrderView({ ...this.state.order, cateringClearingTime: isoDate ?? '' }), resetCateringStatus: true });
        }
    }

    private getSelectedRestrictionNames(): string
    {
        const selectedRestrictionIds = this.state.selectedRestrictions.map(x => x.id)
        const selectedRestrictions = this.state.cateringRestrictions.filter(x => selectedRestrictionIds.includes(x.id));
        return selectedRestrictions.map(x => x.name).toString();
    }

    private restrictionCheckboxSelected(checked: boolean, restriction: ICateringRestrictionView): void
    {
        if (checked)
        {
            this.setState({ selectedRestrictionsEdit: [...this.state.selectedRestrictionsEdit, restriction] })
        }
        if (!checked)
        {
            const updatedItems = this.state.selectedRestrictionsEdit.filter(x => x.id !== restriction.id)
            this.setState({ selectedRestrictionsEdit: updatedItems })
        }
    }

    private displayRestrictionsModal(): void
    {
        this.setState({ showRestrictionsModal: true, selectedRestrictionsEdit: this.state.selectedRestrictions })
    }

    private closeRestrictionsModal(): void
    {
        this.setState({ showRestrictionsModal: false, selectedRestrictionsEdit: [] })
    }

    private applyRestrictionUpdated(): void
    {
        this.setState({ showRestrictionsModal: false, selectedRestrictions: this.state.selectedRestrictionsEdit, selectedRestrictionsEdit: [] })
    }

    private displayFilterModal(): void
    {
        this.setState({ showFilterModal: true, selectedAllergenRestrictionsFilterEdit: this.state.selectedAllergenRestrictionsFilter, selectedDietaryRestrictionsFilterEdit: this.state.selectedDietaryRestrictionsFilter })
    }

    private closeFilterModal(): void
    {
        this.setState({ showFilterModal: false, selectedAllergenRestrictionsFilterEdit: [], selectedDietaryRestrictionsFilterEdit: [] })
    }

    private applyRestrictionFilterUpdated(): void
    {
        const updatedOrderItems = this.state.orderItems.map(x =>
        {
            const itemDietaryRestrictions = x.menuItemRestrictions.filter(restriction => restriction.section == 'Dietary');
            const itemAllergenRestrictions = x.menuItemRestrictions.filter(restriction => restriction.section == 'Allergen');

            let dietaryFilterRequirementsValid = true;
            for (let index = 0; index < this.state.selectedDietaryRestrictionsFilterEdit.length; index++)
            {
                if (this.state.selectedDietaryRestrictionsFilterEdit.length > 0 && !itemDietaryRestrictions.map(x => x.name).includes(this.state.selectedDietaryRestrictionsFilterEdit[index]))
                {
                    dietaryFilterRequirementsValid = false;
                }
            }
            if (this.state.selectedDietaryRestrictionsFilterEdit.length > 0 && itemDietaryRestrictions.length == 0)
            {
                dietaryFilterRequirementsValid = false;
            }

            let allergenRequirementsValid = true;
            for (let index = 0; index < itemAllergenRestrictions.length; index++)
            {
                if (this.state.selectedAllergenRestrictionsFilterEdit.length > 0 && this.state.selectedAllergenRestrictionsFilterEdit.includes(itemAllergenRestrictions[index].name))
                {
                    allergenRequirementsValid = false;
                }
            }

            return (
                {
                    ...x,
                    meetsFilterRequirements: dietaryFilterRequirementsValid && allergenRequirementsValid
                }
            )
        });
        this.setState({
            showFilterModal: false,
            selectedAllergenRestrictionsFilter: this.state.selectedAllergenRestrictionsFilterEdit,
            selectedAllergenRestrictionsFilterEdit: [],
            selectedDietaryRestrictionsFilter: this.state.selectedDietaryRestrictionsFilterEdit,
            selectedDietaryRestrictionsFilterEdit: [],
            orderItems: updatedOrderItems
        });
    }

    private allergenFilterCheckboxSelected(checked: boolean, restriction: string): void
    {

        if (checked)
        {
            this.setState({ selectedAllergenRestrictionsFilterEdit: [...this.state.selectedAllergenRestrictionsFilterEdit, restriction] })
        }
        if (!checked)
        {
            const updatedItems = this.state.selectedAllergenRestrictionsFilterEdit.filter(x => x !== restriction)
            this.setState({ selectedAllergenRestrictionsFilterEdit: updatedItems })
        }
    }

    private dietaryFilterCheckboxSelected(checked: boolean, restriction: string): void
    {

        if (checked)
        {
            this.setState({ selectedDietaryRestrictionsFilterEdit: [...this.state.selectedDietaryRestrictionsFilterEdit, restriction] })
        }
        if (!checked)
        {
            const updatedItems = this.state.selectedDietaryRestrictionsFilterEdit.filter(x => x !== restriction)
            this.setState({ selectedDietaryRestrictionsFilterEdit: updatedItems })
        }
    }

    private getClassificationLabel(classification: string): string
    {
        if (classification == 'MenuItemClassificationFood')
        {
            return this.labels.HubLabelFood
        }
        if (classification == 'MenuItemClassificationSnack')
        {
            return this.labels.HubLabelSnack
        }
        if (classification == 'MenuItemClassificationBeverage')
        {
            return this.labels.HubLabelBeverage
        }
        return '';
    }

    private async updateOrderStatus(status: string): Promise<void>
    {
        this.setState({ loading: true });
        await this.apiClient.cateringOrders.patchCateringOrder(this.state.order.nodeId, this.state.order.orderId, { Catering_Status: status });
        this.props.history.push("/catering-orders/" + this.buildingId);
    }

    private menuChanged(menuId: string): void
    {
        const selectedMenu = this.state.availableCateringMenus.filter(x => x.menu.menuId == menuId);
        const orderItems = selectedMenu[0].orderItems.map(item => ({ ...item, quantity: 0 }))

        this.setState({
            menu: selectedMenu[0].menu,
            orderItems: orderItems,
            showChangeMenuModal: false,
        })
    }

    private getAccordionItems(classification: string): IAccordion[]
    {
        const availableItems = this.state.orderItems?.filter(x => x.classification == classification && x.availableToOrder && x.meetsFilterRequirements);

        return (
            availableItems.map(item =>
            {
                return (
                    {
                        key: item.menuItemId,
                        summary: (
                            <Grid container>
                                <Grid item xs={2} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center', padding: item.imageURI.length > 0 ? '' : '40px 0px' }}>
                                    {item.imageURI.length > 0 &&
                                        <img
                                            width={100}
                                            height={100}
                                            src={item.imageURI}
                                            alt={item.name}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6} md={5} lg={6} sx={{ alignSelf: 'center' }}>
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="h6" gutterBottom>{item.name}</Typography>
                                        <Typography variant="body2" gutterBottom>{item.description}</Typography>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div>
                                                {item.menuItemRestrictions.filter(x => x.section == 'Dietary').map(x =>
                                                {
                                                    return (
                                                        <div className="d-flex" style={{ alignItems: 'center' }}>
                                                            <img height={20} width={20} src={x.imageURI} />
                                                            <div className="ml-1">{x.name}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {
                                                item.menuItemRestrictions.filter(x => x.section == 'Allergen').length > 0 &&
                                                <div style={{ marginLeft: '40px' }}>
                                                    <b>{this.labels.HubLabelContains}:</b> {item.menuItemRestrictions.filter(x => x.section == 'Allergen').map(x => x.name).toString()}
                                                </div>
                                            }
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sx={{ alignSelf: 'center' }}><Typography sx={{ mb: 0 }} variant="body2" gutterBottom>{this.state.currency}{item.unitPrice.toFixed(2)}</Typography></Grid>
                                <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center', fontSize: '23px' }}>
                                    <IbssIconButton
                                        className="mr-1"
                                        aria-label="close"
                                        onClick={() => this.itemQuantityChanged(item.menuItemId, 'remove')}
                                    >
                                        <RemoveCircleOutlineIcon />
                                    </IbssIconButton>
                                    {item.quantity}
                                    <IbssIconButton
                                        className="ml-1"
                                        aria-label="close"
                                        onClick={() => this.itemQuantityChanged(item.menuItemId, 'add')}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IbssIconButton>
                                </Grid>
                            </Grid>
                        ),
                        details: (
                            <div>
                                {this.labels.funcCateringOrderNutritionalInformation_S}:
                                <div>{item.nutritionalInformation}</div>
                            </div>
                        ),
                        hideExpand: item.nutritionalInformation == ''
                    }
                )
            })
        )
    }

    private getNoItemsMessage(classification: string): ReactElement
    {
        const itemsAvailableWithoutFilter = this.state.orderItems?.filter(x => x.classification == classification && x.availableToOrder).length == 0;

        let classificationLabel = '';

        switch (classification)
        {
            case 'MenuItemClassificationBeverage':
                classificationLabel = this.labels.funcCateringOrderBeverages_S.toLocaleLowerCase();
                break;
            case 'MenuItemClassificationSnack':
                classificationLabel = this.labels.funcCateringOrderSnacks_S.toLocaleLowerCase();
                break;
            case 'MenuItemClassificationFood':
                classificationLabel = this.labels.funcCateringOrderFoods_S.toLocaleLowerCase()
                break;
            default:
                break;
        }

        return (
            <div>
                {
                    itemsAvailableWithoutFilter ?
                        <Card sx={{ height: '100%', marginTop: '10px' }}>
                            <div style={{ position: 'relative', top: '15%', textAlign: 'center' }}>
                                <img height={300} width={300} src={`/images/NoCateringMenuAvailable.svg`} alt="" />
                                <Typography className="mb-1" variant="h5">{this.labels.funcCateringOrderNoItems_L.replace('{classification}', classificationLabel)}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '80px' }}>
                                    <div style={{ width: '350px' }}>{this.labels.funcCateringOrderNoItems_Message.replace('{classification}', classificationLabel)}</div>
                                </div>
                            </div>
                        </Card>
                        :
                        <Card sx={{ height: '100%', marginTop: '10px' }}>
                            <div style={{ position: 'relative', top: '15%', textAlign: 'center' }}>
                                <img height={300} width={300} src={`/images/NoCateringMenuAvailable.svg`} alt="" />
                                <Typography className="mb-1" variant="h5">{this.labels.funcCateringOrderNoItemsWithFilter_L.replace('{classification}', classificationLabel)}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '80px' }}>
                                    <div style={{ width: '350px' }}>{this.labels.funcCateringOrderNoItemsWithFilter_Message.replace('{classification}', classificationLabel)}</div>
                                </div>
                            </div>
                        </Card>
                }
            </div>
        )
    }

    public render(): JSX.Element
    {
        const { match, history } = this.props;
        const bookingStart = DateTime.fromISO(this.state.bookingStart);
        const cutOffTimeDays = this.state.preBookCateringTime.split('.')[0];
        const cutOffTimeOfDay = this.state.preBookCateringTime.split('.')[1]?.split(':');
        const cutOffTime = cutOffTimeOfDay && bookingStart.date().plus({ days: -cutOffTimeDays }).set({ hour: parseInt(cutOffTimeOfDay[0]), minute: parseInt(cutOffTimeOfDay[1]) });
        const cutOffDuration = cutOffTime && cutOffTime.diff(DateTime.now().offsetTimeByNode(this.buildingId), ['days', 'hours', 'minutes']).toObject();

        const gridTaskDetailValues: IGridTaskDetailValues[] =
            [
                {
                    details: this.labels.HubLabelLocation,
                    original: this.state.originalOrder ? this.state.originalOrder.spaceId : '',
                    change: this.state.order ? this.state.order.spaceId : ''
                },
                {
                    details: this.labels.HubLabelServiceTime,
                    original: this.state.originalOrder ? `${DateTime.fromISO(this.state.originalOrder.cateringServiceTime).toFormat('HH:mm')}` : '',
                    change: this.state.order ? `${DateTime.fromISO(this.state.order.cateringServiceTime).toFormat('HH:mm')}` : '',
                },
                {
                    details: this.labels.HubLabelCleaningTime,
                    original: this.state.originalOrder ? `${DateTime.fromISO(this.state.originalOrder.cateringClearingTime).toFormat('HH:mm')}` : '',
                    change: this.state.order ? `${DateTime.fromISO(this.state.order.cateringClearingTime).toFormat('HH:mm')}` : '',
                },
                {
                    details: this.labels.HubLabelAttendees,
                    original: this.state.originalOrder ? this.state.originalOrder.cateringAttendees?.toString() : '',
                    change: this.state.order ? this.state.order.cateringAttendees?.toString() : ''
                },
                {
                    details: this.labels.HubLabelCodes,
                    original: this.state.originalOrder ? this.state.originalOrder.costCodeAllocation?.map((x: ICostAllocation) => x.costCode).toString().replaceAll(',', ', ') : '',
                    change: this.state.order ? this.state.costCodes?.map((x: ICostAllocation) => x.costCode).toString().replaceAll(',', ', ') : '',
                },
            ];

        const selectedServiceTimeError = (DateTime.fromISO(this.state.order.cateringServiceTime).toJSDate() < DateTime.fromISO(this.state.bookingStart).toJSDate()) || (DateTime.fromISO(this.state.order.cateringServiceTime).toJSDate() > DateTime.fromISO(this.state.bookingEnd).toJSDate());
        const selectedCleanUpTimeError = (DateTime.fromISO(this.state.order.cateringClearingTime).toJSDate() < DateTime.fromISO(this.state.bookingStart).toJSDate()) || (DateTime.fromISO(this.state.order.cateringClearingTime).toJSDate() > DateTime.fromISO(this.state.bookingEnd).toJSDate());
        const serviceTimeGreaterThanClearUpTime = DateTime.fromISO(this.state.order.cateringServiceTime).toJSDate() > DateTime.fromISO(this.state.order.cateringClearingTime).toJSDate();
        const newOrder = !match.params.spacecateringId;

        const tabs: TabsParameters[] = [
            {
                label: this.labels.funcCateringOrderBeverages_S,
                components: (
                    this.getAccordionItems('MenuItemClassificationBeverage').length > 0 ?
                        <Box className="menu-items-scroll">
                            <IbssAccordion values={this.getAccordionItems('MenuItemClassificationBeverage')} spacing="8px" />
                        </Box>
                        :
                        this.getNoItemsMessage('MenuItemClassificationBeverage')
                )
            },
            {
                label: this.labels.funcCateringOrderSnacks_S,
                components: (
                    this.getAccordionItems('MenuItemClassificationSnack').length > 0 ?
                        <Box className="menu-items-scroll">
                            <IbssAccordion values={this.getAccordionItems('MenuItemClassificationSnack')} spacing="8px" />
                        </Box>
                        :
                        this.getNoItemsMessage('MenuItemClassificationSnack')
                )
            },
            {
                label: this.labels.funcCateringOrderFoods_S,
                components: (
                    this.getAccordionItems('MenuItemClassificationFood').length > 0 ?
                        <Box className="menu-items-scroll">
                            <IbssAccordion values={this.getAccordionItems('MenuItemClassificationFood')} spacing="8px" />
                        </Box>
                        :
                        this.getNoItemsMessage('MenuItemClassificationFood')
                )
            },
        ];

        const allergenRestrictions = this.state.cateringRestrictions.filter(x => x.section == 'Allergen');
        const dietaryRestrictions = this.state.cateringRestrictions.filter(x => x.section == 'Dietary');
        const showStatusUpdateButtons = this.isOneLens;
        const pastCutOffPeriod = cutOffDuration ? (cutOffDuration.days ?? 1) < 1 && (cutOffDuration.hours ?? 1) < 1 && (cutOffDuration.minutes ?? 1) < 1 : false;

        return (
            <>
                {this.state.loading && <LoadingOverlay />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" gutterBottom>{this.labels.funcCateringOrderSelectCatering_M}</Typography>
                            <Typography variant="caption" gutterBottom>{`${this.labels.funcCateringOrderMenuAvailable_S}: ${this.state.menu.name}`}</Typography>
                            <Box component="div">
                                {
                                    newOrder ?
                                        <IbssButton color="secondary" variant="contained" onClick={() => 
                                        {
                                            if (this.props.location.state?.originRoute)
                                            {
                                                history.push(this.props.location.state?.originRoute);
                                            }
                                            else
                                            {
                                                history.push(`/flex-my-bookings/${this.buildingId}/mybooking/${match.params.bookingid}/${match.params.spaceId}`);
                                            }
                                        }
                                        }
                                        >
                                            {this.labels.HubLabelBackToBooking}
                                        </IbssButton>
                                        :
                                        <>
                                            <IbssButton color="secondary" variant="contained" className="m-2" onClick={() => this.cancelChanges()}>{this.labels.HubLabelCancelChanges}</IbssButton>
                                            <IbssButton disabled={selectedServiceTimeError || selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime || (!this.isOneLens && pastCutOffPeriod) || !this.state.order.cateringServiceTime || !this.state.order.cateringClearingTime} variant="contained" className="m-2" onClick={() => this.setState({ showConfirmationModal: true })}>{this.labels.HubLabelSaveChanges}</IbssButton>
                                        </>
                                }
                            </Box>
                        </div>
                        <div style={{ float: 'right' }}>
                            <Typography variant="h5" gutterBottom style={{ fontSize: '14px' }} className='text-danger'>{selectedServiceTimeError || selectedCleanUpTimeError ? this.labels.HubLabelCateringDetailsErrorMsg : serviceTimeGreaterThanClearUpTime ? this.labels.HubLabelServiceTimeGreaterThanCleanUpTimeMsg : ''}</Typography>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item md={7} xl={8}>
                                <div className="mt-1 d-flex justify-content-between">
                                    <IbssButton variant="contained" onClick={() => this.displayFilterModal()} >{this.labels.HubLabelFilter}</IbssButton>
                                    {
                                        this.state.availableCateringMenus.length > 0 &&
                                        <div className="d-flex">
                                            <div className="mr-2" style={{ paddingTop: '6px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400 }}>{this.labels.funcCateringOrderSelectedMenu_Short}: <strong>{this.state.menu.name}</strong></div>
                                            <IbssButton variant="contained" color="secondary" onClick={() => this.setState({ showChangeMenuModal: true })} >{this.labels.funcCateringOrderChangeMenu_Short}</IbssButton>
                                        </div>
                                    }
                                </div>
                                <ChangeMenuModal
                                    open={this.state.showChangeMenuModal}
                                    onClose={() => this.setState({ showChangeMenuModal: false })}
                                    onChange={(menuId: string) => this.menuChanged(menuId)}
                                    options={this.state.menuDropdownOptions}
                                    selectedMenu={this.state.menu.menuId}
                                />
                            </Grid>
                            <Grid item md={7} xl={8}>
                                {
                                    this.state.skeletonLoad ?
                                        <div>
                                            <Card sx={{ height: '124px', marginBottom: '10px', marginTop: '10px' }}><MenuItemSkeleton /></Card>
                                            <Card sx={{ height: '124px' }}><MenuItemSkeleton /></Card>
                                        </div> :
                                        this.state.menu.menuId == '' ?
                                            <Card sx={{ height: '100%' }}>
                                                <div style={{ position: 'relative', top: '15%', textAlign: 'center' }}>
                                                    <img height={300} width={300} src={`/images/NoCateringMenuAvailable.svg`} alt="" />
                                                    <Typography className="mb-1" variant="h5">{this.labels.funcCateringOrderNoMenuAvailable_S}</Typography>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ width: '350px' }}>{this.labels.funcCateringOrderNoMenuAvailable_Message}</div>
                                                    </div>
                                                </div>
                                            </Card>
                                            :
                                            <IbssHorizontalTabs
                                                color="white"
                                                tabs={tabs}
                                                orientation="horizontal"
                                                boxwidth='100%'
                                            />
                                }
                            </Grid>
                            <Grid item md={5} xl={4}>
                                <Card className="ml-2">
                                    {
                                        this.state.skeletonLoad ?
                                            <SummaryCardSkeleton />
                                            :
                                            <Box sx={{ p: 4 }}>
                                                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                                    <Typography variant="h5" gutterBottom>{this.labels.HublabelSummary}</Typography>
                                                    {
                                                        !newOrder &&
                                                        <div>{this.renderTaskOverAllStatus(this.state.order.cateringStatus)}</div>
                                                    }
                                                </div>
                                                <Card style={{ backgroundColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiBackGround : customTheme.darkTheme.uiBackGround }}>
                                                    <Box component="div" sx={{ m: 2 }}>
                                                        <Typography variant="overline" fontSize={14} display="block" gutterBottom style={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>{this.state.order.bookingName}</Typography>
                                                        <Typography sx={{ fontSize: 24, fontWeight: 500 }} gutterBottom>{this.getSpaceNameById(this.state.order?.spaceId)}</Typography>
                                                        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                                                            <Box display="flex" gridColumn="span 1" gap={1}>
                                                                <IbssSvgIcon fontSize="small">{Icons.calenderIcon}</IbssSvgIcon>
                                                                {moment(this.state.bookingStart).format('DD/MM/yyyy')}
                                                            </Box>
                                                            <Box display="flex" gridColumn="span 1" gap={1}>
                                                                <IbssSvgIcon fontSize="medium">{Icons.TimeIcon}</IbssSvgIcon>
                                                                {`${DateTime.fromISO(this.state.bookingStart).toFormat('HH:mm')} - ${DateTime.fromISO(this.state.bookingEnd).toFormat('HH:mm')}`}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Card>
                                                <Box component="div" sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-around' }}>
                                                    <Box style={{ alignSelf: 'center' }}>
                                                        {this.labels.HubLabelService}
                                                        <div style={{ fontSize: '20px' }}>
                                                            <IbssTimePicker
                                                                className='ibss-timepicker'
                                                                value={DateTime.fromISO(this.state.order.cateringServiceTime).toJSDate()}
                                                                onChange={e => e !== null ? this.handleChangeCateringServiceTime(new Date(e)) : {}}
                                                                ampm={false}
                                                                minutesStep={1}
                                                                disabled={!this.isOneLens && pastCutOffPeriod}
                                                                renderInput={(params) =>
                                                                {
                                                                    const { sx, ...paramsMinusSx } = params
                                                                    return <TextField fullWidth {...paramsMinusSx} error={selectedServiceTimeError || serviceTimeGreaterThanClearUpTime || !this.state.order.cateringServiceTime} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                    />
                                                                }}
                                                            />
                                                        </div>
                                                    </Box>
                                                    <div
                                                        className="icon-text-inline mt-3"
                                                        style={{
                                                            padding: "10px 22px",
                                                        }}>
                                                        <img src={`/images/Sidebar_Icons/${this.appState.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                                    </div>
                                                    <div style={{ alignSelf: 'center' }}>
                                                        <div>
                                                            {this.labels.HubLabelCleanUp}
                                                        </div>
                                                        <div style={{ fontSize: '23px' }}>
                                                            <IbssTimePicker
                                                                className='ibss-timepicker'
                                                                value={DateTime.fromISO(this.state.order.cateringClearingTime).toJSDate()}
                                                                onChange={e => e !== null ? this.handleChangeCateringClearingTime(new Date(e)) : {}}
                                                                ampm={false}
                                                                minutesStep={1}
                                                                disabled={!this.isOneLens && pastCutOffPeriod}
                                                                renderInput={(params) =>
                                                                {
                                                                    const { sx, ...paramsMinusSx } = params
                                                                    return <TextField fullWidth {...paramsMinusSx} error={selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime || !this.state.order.cateringClearingTime} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                    />
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Box>
                                                <div className="d-flex">
                                                    <IbssSvgIcon fontSize="small" className="mr-1">{Icons.TimerIcon}</IbssSvgIcon>
                                                    <Typography gutterBottom>
                                                        {`${this.labels.HubLabelCutOffTime}: ${cutOffDuration?.days} ${this.labels.HubLabelDays}, ${cutOffDuration?.hours} ${this.labels.HubLabelHours}, ${Math.round(cutOffDuration?.minutes || 0)} ${this.labels.HubLabelMinutes}`}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        multiline
                                                        disabled={!this.isOneLens && pastCutOffPeriod}
                                                        color="secondary"
                                                        placeholder={this.labels.HubLabelAddNotes}
                                                        value={this.state.order?.cateringNotes}
                                                        rows={4}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        fullWidth
                                                        onChange={e => this.state.order ? this.setState({ order: new OrderView({ ...this.state.order, cateringNotes: e.target.value }) }) : {}}
                                                    />
                                                </div>
                                                <Box className="mt-1" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography variant="overline" fontSize={14} display="block" gutterBottom>{`${this.state.orderItems?.map(x => x.quantity).reduce((x, y) => { return x + y }, 0)} ${this.labels.HubLabelItems}`}</Typography>
                                                    <Typography variant="h3" display="block" gutterBottom>{`${this.state.currency}${this.state.orderItems?.map(x => x.unitPrice * x.quantity).reduce((x, y) => { return x + y }, 0).toFixed(2)}`}</Typography>
                                                </Box>
                                                {(this.localStorage.hasRight("API.Catering.AssignCostCode")) && (
                                                    <div className="d-flex mb-3">
                                                        <div className="mr-3">{this.labels.HubLabelCostCodes}:</div>
                                                        <div style={{ cursor: 'pointer', color: '#4380cb', display: 'flex', flexWrap: 'wrap' }} onClick={() => this.handleCostCodeModal()}>{this.state.order.costCodeAllocation.length == 0 ? 'Add +' : this.state.costCodes.map(x => x.costCode).toString()}</div>
                                                    </div>
                                                )
                                                }
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <div className="mr-3">{this.labels.funcCateringOrderAllergies_S}:</div>
                                                    <div style={{ cursor: 'pointer', color: '#4380cb', display: 'flex', flexWrap: 'wrap' }} onClick={() => this.displayRestrictionsModal()}>{this.state.selectedRestrictions.length == 0 ? 'Add +' : this.getSelectedRestrictionNames()}</div>
                                                </div>
                                                {
                                                    newOrder &&
                                                    <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <IbssButton disabled={selectedServiceTimeError || selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime || (!this.isOneLens && pastCutOffPeriod) || !this.state.order.cateringServiceTime || !this.state.order.cateringClearingTime} variant="contained" className="m-2" onClick={() => this.create()}>{this.labels.HubLabelConfirmOrder}</IbssButton>
                                                    </Box>
                                                }
                                            </Box>
                                    }

                                </Card>
                            </Grid>
                            <Modal show={this.state.showConfirmationModal} onHide={() => this.setState({ showConfirmationModal: false })}>
                                <Modal.Header>
                                    <Modal.Title>{this.labels.HubLabelTaskId}: {this.state.order?.orderId}</Modal.Title>
                                    <button type="button" className="close" onClick={() => this.setState({ showConfirmationModal: false })} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </Modal.Header>
                                <div className='m-4'>
                                    {this.labels.HubLabelEditCateringTaskModalSubtext}
                                </div>
                                {/* Task details */}
                                <div className="container task-defination-popup">
                                    <div className="row" style={{ paddingBottom: '10px', fontSize: '18px' }}>
                                        <div className="col-4">
                                            {this.labels.HubLabelDetailslabel}
                                        </div>
                                        <div className="col-4">
                                            {this.labels.HubLabelOriginal}
                                        </div>
                                        <div className="col-4">
                                            {this.labels.HublabelChange}
                                        </div>
                                    </div>
                                    {gridTaskDetailValues.map(x =>
                                    {
                                        return (
                                            <div key={x.details} className="row" style={{ paddingBottom: '10px' }}>
                                                <div className="col-4">
                                                    {x.details}
                                                </div>
                                                <div className="col-4">
                                                    {x.original}
                                                </div>
                                                <div className="col-4">
                                                    {x.change}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <hr />
                                    <Box>
                                        <Grid container spacing={2} width={'auto'}>
                                            <Grid item xs={3}>
                                                {this.labels.HubLabelSection}
                                            </Grid>
                                            <Grid item xs={5}>
                                                {this.labels.HubLabelItem}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {this.labels.HubLabelQty}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {this.labels.HubLabelCost}
                                            </Grid>

                                            {this.state.orderItems.map((x) =>
                                            {
                                                if (x.quantity > 0)
                                                {
                                                    return (
                                                        <Grid container item key={x.menuItemId} spacing={2} style={{ paddingBottom: '10px' }}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body1">
                                                                    {this.getClassificationLabel(x.classification)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography variant="body1">
                                                                    {x.name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="body1">
                                                                    {x.quantity}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="body1">
                                                                    {this.state.currency}
                                                                    {(x.unitPrice * x.quantity).toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                        </Grid>
                                    </Box>
                                </div>
                                {/* Order details */}
                                <Modal.Footer>
                                    <div style={{ textAlign: 'center' }}>
                                        <IbssButton
                                            style={{ height: '45px', minWidth: '100px' }}
                                            color='error'
                                            variant="contained"
                                            onClick={() => this.updateOrder()}
                                        >
                                            {this.labels.HubButtonSave}
                                        </IbssButton>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                            {/* -----------------------------Start Restrictions Popup---------------------------*/}
                            <IbssDialog
                                open={this.state.showRestrictionsModal}
                                onClose={() => this.closeRestrictionsModal()}
                                content=
                                {
                                    <>
                                        <div className="mb-3">{this.labels.funcCateringOrderAllergens_D}</div>
                                        <Divider />
                                        <div className="mb-2 mt-1">{this.labels.funcCateringOrderRemoveFoods_M}:</div>
                                        <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {allergenRestrictions.map(restriction => (<IbssCheckBox checked={this.state.selectedRestrictionsEdit.map(x => x.id).includes(restriction.id)} label={restriction.name} onClicked={(e: { target: { checked: boolean } }) => this.restrictionCheckboxSelected(e.target.checked, restriction)} />))}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <IbssButton variant="contained" onClick={() => this.applyRestrictionUpdated()}>{this.labels.HubButtonSave}</IbssButton>
                                        </div>
                                    </>
                                }
                                header={this.labels.funcCateringOrderAllergyInformation_S}
                                fullWidth
                            />
                            {/* -----------------------------End Restrictions Popup---------------------------*/}

                            {/* -----------------------------Start Filter Popup---------------------------*/}
                            <IbssDialog
                                open={this.state.showFilterModal}
                                onClose={() => this.closeFilterModal()}
                                content=
                                {
                                    <>
                                        <div className="mb-3">{this.labels.funcCateringOrderFilter_Message}</div>
                                        <Divider />
                                        <div className="mb-2 mt-1">{this.labels.funcCateringOrderRemoveFoods_M}:</div>
                                        <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {allergenRestrictions.map(restriction => (<IbssCheckBox checked={this.state.selectedAllergenRestrictionsFilterEdit.includes(restriction.name)} label={restriction.name} onClicked={(e: { target: { checked: boolean } }) => this.allergenFilterCheckboxSelected(e.target.checked, restriction.name)} />))}
                                        </div>
                                        <Divider />
                                        <div className="mb-2 mt-1">{this.labels.funcCateringOrderOnlyShowMarkedFoods_M}:</div>
                                        <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {dietaryRestrictions.map(restriction => (<IbssCheckBox checked={this.state.selectedDietaryRestrictionsFilterEdit.includes(restriction.name)} label={restriction.name} onClicked={(e: { target: { checked: boolean } }) => this.dietaryFilterCheckboxSelected(e.target.checked, restriction.name)} />))}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <IbssButton variant="contained" onClick={() => this.applyRestrictionFilterUpdated()}>{this.labels.funcCateringOrderApply_S}</IbssButton>
                                        </div>
                                    </>
                                }
                                header={this.labels.funcCateringOrderFilterBy_S}
                                fullWidth
                            />
                            {/* -----------------------------End Filter Popup---------------------------*/}

                            {/* -----------------------------Start Cost codes Popup---------------------------*/}
                            <CostCodesDialog
                                buildingId={this.buildingId}
                                selectedCostCodes={this.state.costCodes}
                                show={this.state.showCostCodeModal}
                                onClose={() => this.handleCostCodeModal()}
                                updateBookingCostCodes={(updatedCostCodes) => this.updateBookingCostCodes(updatedCostCodes)}
                            />
                            {/* -----------------------------End Cost codes Popup---------------------------*/}
                        </Grid>
                        <Modal show={this.state.showStatusUpdateConfirmation != ''} onHide={() => this.setState({ showStatusUpdateConfirmation: '' })}>
                            <Modal.Header>
                                <Modal.Title>{this.labels.HubLabelModalConfirmTitle}</Modal.Title>
                                <button type="button" className="close" onClick={() => this.setState({ showStatusUpdateConfirmation: '' })} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </Modal.Header>
                            <div className='m-4'>
                                {this.labels.funcCateringOrderStausUpdate_Message}
                            </div>
                            <Modal.Footer>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        color='secondary'
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: '' })}
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButton>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        color='primary'
                                        variant="contained"
                                        onClick={() => this.updateOrderStatus(this.state.showStatusUpdateConfirmation)}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButton>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {
                            showStatusUpdateButtons &&
                            <div className="mt-1">
                                {
                                    this.state.order.cateringStatus == 'PendingApproval' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        color='error'
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'NotApproved' })}
                                    >
                                        {this.labels.funcCateringOrderDecline_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'PendingApproval' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'RequestApproved' })}
                                    >
                                        {this.labels.HubLabelApprove}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'RequestApproved' && !pastCutOffPeriod &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'ReadyForPrep' })}
                                    >
                                        {this.labels.funcCateringOrderReadyForPrep_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'ReadyForPrep' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'PrepareForService' })}
                                    >
                                        {this.labels.funcCateringOrderPrepareForService_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'PrepareForService' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'ReadyForDespatchInTime' })}
                                    >
                                        {this.labels.funcCateringOrderReadyInTime_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'PrepareForService' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'ReadyForDespatchOutOfTime' })}
                                    >
                                        {this.labels.funcCateringOrderReadyLate_S}
                                    </IbssButton>
                                }
                                {
                                    (this.state.order.cateringStatus == 'ReadyForDespatchInTime' || this.state.order.cateringStatus == 'ReadyForDespatchOutOfTime') &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'Delivered' })}
                                    >
                                        {this.labels.funcCateringOrderDelivered_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'Delivered' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'DeliveryIssue' })}
                                    >
                                        {this.labels.funcCateringOrderDeliveryIssue_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'Delivered' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'ClearUpScheduled' })}
                                    >
                                        {this.labels.funcCateringOrderClearedUpInTime_S}
                                    </IbssButton>
                                }
                                {
                                    this.state.order.cateringStatus == 'Delivered' &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'ClearUpExcess' })}
                                    >
                                        {this.labels.funcCateringOrderClearedUpLate_S}
                                    </IbssButton>
                                }
                                {
                                    (this.state.order.cateringStatus == 'ClearUpExcess' || this.state.order.cateringStatus == 'ClearUpScheduled') &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'Cleared' })}
                                    >
                                        {this.labels.funcCateringOrderCleared_S}
                                    </IbssButton>
                                }
                                {
                                    (this.state.order.cateringStatus == 'RequestApproved' ||
                                        this.state.order.cateringStatus == 'ReadyForPrep' ||
                                        this.state.order.cateringStatus == 'PrepareForService' ||
                                        this.state.order.cateringStatus == 'ReadyForDespatchInTime' ||
                                        this.state.order.cateringStatus == 'DeliveryIssue' ||
                                        this.state.order.cateringStatus == 'ReadyForDespatchOutOfTime') &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        color="error"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'CancelledCharged' })}
                                    >
                                        {this.labels.funcCateringOrderCancelOrderCharge_S}
                                    </IbssButton>
                                }
                                {
                                    (this.state.order.cateringStatus == 'RequestApproved' ||
                                        this.state.order.cateringStatus == 'ReadyForPrep' ||
                                        this.state.order.cateringStatus == 'PrepareForService' ||
                                        this.state.order.cateringStatus == 'ReadyForDespatchInTime' ||
                                        this.state.order.cateringStatus == 'DeliveryIssue' ||
                                        this.state.order.cateringStatus == 'ReadyForDespatchOutOfTime') &&
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                        variant="contained"
                                        color="error"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: 'CancelledNoCharge' })}
                                    >
                                        {this.labels.funcCateringOrderCancelOrderNoCharge_S}
                                    </IbssButton>
                                }
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }

    public renderTaskOverAllStatus(status: string): React.ReactNode
    {
        if (status === "PendingApproval")
        {
            return <IbssChip label={this.labels.HubLabelPendingApproval} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (status === "NotApproved")
        {
            return <IbssChip label={this.labels.HubLabelNotApproved} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (status === "RequestApproved")
        {
            return <IbssChip label={this.labels.HubLabelRequestApproved} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (status === "ReadyForPrep")
        {
            return <IbssChip label={this.labels.HubLabelReadyForPrep} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (status === "PrepareForService")
        {
            return <IbssChip label={this.labels.HubLabelPrepareForService} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (status === "InPreparation")
        {
            return <IbssChip label={this.labels.HubLabelInPreparation} sx={{
                backgroundColor: 'var(--ui-primary-pastel )',
                color: "var(ui-text)",
            }} />;
        }
        else if (status === "ReadyForDespatchInTime")
        {
            return <IbssChip label={this.labels.HubLabelReadyForDispatchInTime} sx={{
                backgroundColor: 'var(--ui-primary-pastel )',
                color: "var(ui-text)",
            }} />;
        }
        else if (status === "ReadyForDespatchOutOfTime")
        {
            return <IbssChip label={this.labels.HubLabelReadyForDispatchOutOfTime} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (status === "CancelledCharged")
        {
            return <IbssChip label={this.labels.HubLabelCancelledCharged} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (status === "CancelledNoCharge")
        {
            return <IbssChip label={this.labels.HubLabelCancelledNoCharge} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (status === "DeliveryIssue")
        {
            return <IbssChip label={this.labels.HubLabelDeliveryIssue} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (status === "Delivered")
        {
            return <IbssChip label={this.labels.HubLabelDelivered} sx={{
                backgroundColor: 'var(--ui-primary-pastel )',
                color: "var(ui-text)",
            }} />;
        }
        else if (status === "ClearUpScheduled")
        {
            return <IbssChip label={this.labels.HubLabelClearUpScheduled} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (status === "ClearUpExcess")
        {
            return <IbssChip label={this.labels.HubLabelClearUpExcess} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (status === "Cleared")
        {
            return <IbssChip label={this.labels.HubLabelCleared} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }
}

export class MenuItemSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <div style={{ display: 'flex', padding: '10px' }}>
                <Skeleton variant="rectangular" width={100} height={100} />
                <div style={{ width: '100%' }}>
                    <Skeleton sx={{ margin: '15px', marginBottom: '10px' }} variant="rectangular" width={'70%'} height={10} />
                    <Skeleton sx={{ margin: '15px' }} variant="rectangular" width={'50%'} height={10} />
                </div>
            </div>
        )
    }
}

export class SummaryCardSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <div style={{ width: '100%', padding: '10px', height: '450px' }}>
                <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width={'50%'} height={10} />
                <div style={{ textAlign: 'center' }}>
                    <Skeleton variant="rectangular" width={'95%'} height={100} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'35%'} height={10} />
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'35%'} height={10} />
                </div>
                <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width={'70%'} height={10} />
                <div style={{ textAlign: 'center' }}>
                    <Skeleton variant="rectangular" width={'95%'} height={70} />
                </div>
                <div style={{ display: 'flex', marginTop: '30px' }}>
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'15%'} height={10} />
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'45%'} height={10} />
                </div>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'15%'} height={10} />
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'35%'} height={10} />
                </div>
            </div>
        )
    }
}

export interface IProps extends RouteComponentProps<IQueryParams, StaticContext, IRouteState>, IPropsFromState
{
}

export interface IQueryParams
{
    bookingid: string;
    buildingid: string;
    spacecateringId: string;
    spaceId: string;
}

export interface IRouteState
{
    bookingId?: string;
    originRoute?: string;
}

export interface IState
{
    order: OrderView;
    originalOrder: OrderView;
    orderItems: CateringMenuItemView[];
    loading: boolean;
    currency: string;
    preBookCateringTime: string;
    bookingStart: string;
    bookingEnd: string;
    showConfirmationModal: boolean;
    floorId: number;
    numOfAttendees: number;
    spaces: Space[];
    showCostCodeModal: boolean;
    lightModeTheme: boolean;
    costCodes: CostCodeWithAllocation[];
    menu: ICateringMenuView;
    cateringRestrictions: ICateringRestrictionView[];
    selectedRestrictions: ICateringRestrictionView[];
    selectedRestrictionsEdit: ICateringRestrictionView[];
    showRestrictionsModal: boolean;
    selectedAllergenRestrictionsFilter: string[],
    selectedAllergenRestrictionsFilterEdit: string[],
    selectedDietaryRestrictionsFilter: string[],
    selectedDietaryRestrictionsFilterEdit: string[],
    showFilterModal: boolean,
    itemOrderPolicies: ICateringOrderPolicyView[],
    skeletonLoad: boolean,
    bookingId: string,
    showStatusUpdateConfirmation: string,
    resetCateringStatus: boolean,
    menuDropdownOptions: IDropdownOptions[],
    showChangeMenuModal: boolean
    availableCateringMenus: MenuView[]
}

export class OrderView
{
    public orderId = "";
    public nodeId = 0;
    public menuItems = new Array<MenuItemView>();
    public cateringServiceTime = "";
    public cateringClearingTime = "";
    public cateringStatus = "";
    public cateringAttendees = "";
    public cateringNotes = "";
    public costCodeAllocation: ICostAllocation[] = [];
    public cateringOrderRestrictions = [];
    public bookingName = "";
    public bookingId = "";
    public spaceName = "";
    public spaceId = "";
    public menuId = "";

    constructor(value?: Partial<OrderView>)
    {
        if (value == null)
        {
            return;
        }
        Object.assign(this, value);
    }

    public static fromApiTask(data: ICateringOrder): OrderView
    {
        return new OrderView(
            {
                orderId: data.Order_Id,
                nodeId: data.Node_Id,
                menuItems: data.Menu_Items.map(i => MenuItemView.fromApiModel(i)),
                cateringServiceTime: data.Catering_Service_Time,
                cateringClearingTime: data.Catering_Clearing_Time,
                cateringStatus: data.Catering_Status,
                cateringAttendees: data.Catering_Attendees,
                cateringNotes: data.Catering_Notes,
                costCodeAllocation: JSON.parse(data.Cost_Code_Allocation).map((x: { Cost_Code: string; Cost_Code_Id: string; Allocation: number }) => ({ costCode: x.Cost_Code, costCodeId: x.Cost_Code_Id, allocation: x.Allocation })),
                cateringOrderRestrictions: data.Catering_Order_Restrictions.length > 0 ? JSON.parse(data.Catering_Order_Restrictions) : [],
                bookingName: data.Booking_Name,
                bookingId: data.Booking_Id,
                spaceName: data.Space_Name,
                spaceId: data.Space_Id,
                menuId: data.Menu_Id
            });
    }

    public toApiOrder(spaceId: string, bookingId: string, nodeId: number, numOfAttendees: number, orderItems: string, costCodeAllocations: ICostCodeAllocation[], orderTotal: number, restrictions: ICateringRestrictionView[], orderStatus: string): ICreateCateringOrder
    {
        const payload: ICreateCateringOrder =
        {
            Node_Id: nodeId,
            Menu_Items: orderItems,
            Booking_Id: bookingId,
            Space_Id: spaceId,
            Catering_Service_Time: this.cateringServiceTime,
            Catering_Clearing_Time: this.cateringClearingTime,
            Catering_Status: orderStatus,
            Catering_Attendees: numOfAttendees,
            Catering_Notes: this.cateringNotes,
            Cost_Code_Allocation: JSON.stringify(costCodeAllocations),
            Catering_Total_Value: orderTotal,
            Catering_Order_Restrictions: JSON.stringify(restrictions)
        };

        return payload;
    }
}

export class MenuItemView
{
    public id = "";
    public menuId = "";
    public quantityOfItems = 0;

    public static fromApiModel(data: IMenuItem): MenuItemView
    {
        return {
            id: data.Id,
            menuId: data.Menu_Id,
            quantityOfItems: data.QuantityOfItems,
        };
    }
}

export class MenuView
{
    public menu = {
        menuId: '',
        name: ''
    };
    public orderItems: CateringMenuItemView[] = [];

    public static itemAvailableToOrder(bookingData: IGetV2BookingResponse, menuItem: IMenuItems, orderPolicy: ICateringOrderPolicy[]): boolean
    {

        const itemPolicy = orderPolicy.filter(policy => policy.OrderPolicy_Id == menuItem.MenuItem.OrderPolicy_Id)[0];

        let availableToOrder = true;
        if (itemPolicy)
        {
            const prepCompletionTime = DateTime.now().plus({ minutes: itemPolicy.PreparationTime_Mins });
            const kitchenOpenWithinBookingTimes = parseInt(DateTime.fromISO(bookingData.Booking_Start).toFormat('HH:mm').replace(':', '')) >= parseInt(itemPolicy.Kitchen_OperatingHours_Start.replace(':', '')) && parseInt(DateTime.fromISO(bookingData.Booking_End).toFormat('HH:mm').replace(':', '')) <= parseInt(itemPolicy.Kitchen_OperatingHours_Stop.replace(':', ''));
            if (prepCompletionTime > DateTime.fromISO(bookingData.Booking_Start) || !kitchenOpenWithinBookingTimes)
            {
                availableToOrder = false;
            }
        }
        return availableToOrder;
    }

    public static fromApiModel(cateringMenu: ICateringMenu, order: OrderView | null, isOneLens: boolean, bookingData: IGetV2BookingResponse, orderPolicy: ICateringOrderPolicy[]): MenuView
    {
        return {
            menu: {
                menuId: cateringMenu.Menu_Id,
                name: cateringMenu.Name,
            },
            orderItems: cateringMenu.Menu_MenuItems.map(menuItem =>
            {
                const item = (order == null ? null : order.menuItems.filter(i => i.id == menuItem.MenuItem_Id)[0]);
                const availableToOrder = isOneLens ? true : this.itemAvailableToOrder(bookingData, menuItem, orderPolicy);

                return ({
                    description: menuItem.MenuItem.Description,
                    status: menuItem.MenuItem.Status,
                    unitPrice: menuItem.MenuItem.UnitPrice,
                    classification: menuItem.MenuItem.Classification,
                    retailPrice: menuItem.MenuItem.RetailPrice,
                    name: menuItem.MenuItem.Name,
                    supplierId: menuItem.MenuItem.Supplier_Id,
                    menuItemId: menuItem.MenuItem_Id,
                    nutritionalInformation: menuItem.MenuItem.NutritionalInformation,
                    orderPolicy_Id: menuItem.MenuItem.OrderPolicy_Id,
                    imageURI: menuItem.MenuItem.ImageURI,
                    quantity: item?.quantityOfItems ?? 0,
                    meetsFilterRequirements: true,
                    availableToOrder: availableToOrder,

                    menuItemRestrictions: menuItem.MenuItem.MenuItem_Restrictions.map(x => ({
                        id: x.Restriction_Id || '',
                        section: x.Restriction.Section,
                        imageURI: x.Restriction.ImageURI,
                        name: x.Restriction.Name
                    }))
                })
            }),
        };
    }
}

export interface ICostAllocation
{
    costCode: string;
    costCodeId: string;
    allocation: number;
    costCodeDescription: string;
}

export interface IGridTaskDetailValues
{
    details: string;
    original: string;
    change: string;
}

export interface CateringMenuItemView
{
    description: string;
    status: Status;
    unitPrice: number;
    classification: Classification;
    retailPrice: number;
    name: string;
    supplierId: string;
    menuItemId: string;
    nutritionalInformation: string;
    orderPolicy_Id: string;
    imageURI: string;
    quantity: number;
    availableToOrder: boolean;
    menuItemRestrictions: ICateringRestrictionView[];
    meetsFilterRequirements: boolean;
}

export interface ICateringRestrictionView
{
    id: string;
    section: string;
    imageURI: string;
    name: string;
}

export interface ICateringMenuView
{
    menuId: string;
    name: string;
}

export interface ICateringOrderPolicyView
{
    policyId: string;
    advancedNotice: string;
    preperationTimeMins: number;
    kitchenHoursStart: string;
    kitchenHoursStop: string;
}