import React, { Component } from 'react'
import { Box, Grid, SelectChangeEvent, TextField, Typography } from "@mui/material";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { appContext } from '../../../../AppContext';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import MediaSelector, { IFile } from '../../../../Components/DialogLaunchers/MediaSelector/MediaSelector';
import { IFilterOptions, IFilterOptionsNumber } from './EditAdvancedSpace.tsx';

interface ISpaceConfigurationProps 
{
    spaceClasses: IFilterOptions[],
    selectedSpaceClass: string;
    onSpaceClassChange: (event: SelectChangeEvent<string>) => void; 
    spaceTypeOptions: IFilterOptions[];
    selectedSpaceType: string;
    onSpaceTypeChange: (event: SelectChangeEvent<string>) => void; 
    spaceTypeLabel: string;
    onSpaceTypeLabelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    workSpaceTypeOptions: IFilterOptions[];
    selectedWorkSpaceType: string;
    onWorkSpaceChange: (event: SelectChangeEvent<string>)  => void; 
    capacity: number;
    onCapacityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;   
    selectedBuildingOption: string;
    isCreate: boolean;
    selectedSpaceClassOptionError: boolean;
    selectedSpaceTypeOptionError: boolean;
    selectedWorkTypeOptionError: boolean;
    capacityLabelError: boolean;
}



export default class SpaceType extends Component<ISpaceConfigurationProps> 
{
  private get labels() { return appContext().labels; }
 

  render(): JSX.Element
  {
    const {
        isCreate,
        spaceClasses,
        selectedSpaceClass,
        onSpaceClassChange,
        spaceTypeOptions,
        selectedSpaceType,
        onSpaceTypeChange,
        spaceTypeLabel,
        onSpaceTypeLabelChange,
        workSpaceTypeOptions,
        selectedWorkSpaceType,
        onWorkSpaceChange,
        capacity,
        onCapacityChange, 
        selectedBuildingOption, 
        capacityLabelError,
        selectedSpaceClassOptionError,
        selectedSpaceTypeOptionError,
        selectedWorkTypeOptionError,
    } = this.props;
    return (
        <Box className="tablePanel" sx={{'& > :not(style)': { my: 1},}}>
        <Box mt={2} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'} sx={{'& > :not(style)': { mt: 1},}}>
                <Box className="icon-bg-dark" mr={1} p={1.5}>
                    <IbssSvgIcon sx={{color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                        {Icons.SpacesIcon}
                    </IbssSvgIcon>
                </Box>
                <Typography variant="h6" sx={{fontWeight:'regular'}}>{this.labels.HubLabelSpaceType}</Typography>
            </Box>
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
            <IbssInputDropDown 
                options={spaceClasses}
                id="Space Class"
                value={selectedSpaceClass}
                inputLabel={this.labels.HubLabelSpaceClass}
                error={selectedSpaceClassOptionError}
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onSpaceClassChange(event)}
            />
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
        <IbssInputDropDown 
            options={spaceTypeOptions}
            disabled={selectedBuildingOption == "" && isCreate}
            id="Space Type"
            value={selectedSpaceType}
            inputLabel={this.labels.HubLabelSpaceType}
            error={selectedSpaceTypeOptionError}
            fullWidth
            onChange={(event: SelectChangeEvent<string>) => onSpaceTypeChange(event)}
        />
        <IbssTextField 
            fullWidth
            value={spaceTypeLabel}
            label={this.labels.HubLabelSpaceTypeLabel}
            variant="outlined" 
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSpaceTypeLabelChange(event)}
        />
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
        <IbssInputDropDown 
            options={workSpaceTypeOptions}
            id="work Type"
            disabled={selectedBuildingOption == "" && isCreate}
            value={selectedWorkSpaceType}
            inputLabel={this.labels.HubLabelworkType}
            error={selectedWorkTypeOptionError}
            fullWidth
            onChange={(event: SelectChangeEvent<string>) => onWorkSpaceChange(event)}
        />
        <IbssTextField
            label={this.labels.HubLabelCapacity}
            variant="outlined"
            type="number"
            error={capacityLabelError}
            fullWidth
            value={capacity}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onCapacityChange(event)}
        />
        </Box>
    </Box>
    )
  }
}
