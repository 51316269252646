import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { getAllBuildingsData } from "../../../../Common/Helper";
import { connect } from "react-redux";
import History from "history";
import { appContext } from "../../../../AppContext";
import { TextField } from '@mui/material';
import { ONELENS_SPACEANALYTICS_DETAIL } from "../../../../app/constants";
import IbssDatePicker from "../../../../Components/Inputs/DatePicker/IbssDatePicker";
import { withRouter } from "react-router-dom";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import { DateHelper } from "../../../../Common/DateHelper";
import { DateTime } from "luxon";

type OptionNumType = {
    label: string,
    value: number
}

type OptionStrType = {
    label: string,
    value: string
}

type SearchCriteriaState = {
    buildingOptions: Array<OptionNumType>,
    workSpaceTypeOptions: Array<OptionStrType>,
    SpaceTypeOptions: Array<OptionStrType>,
    floorTypeOptions: Array<OptionNumType>,
    zoneOptions: Array<OptionStrType>,
    selectedBuildingOption: string | number[], // mistyped in Class State as string | number[]? observed a number in React Components.
    selectedWorkspaceTypes: string,
    selectedSpaceTypes: string;
    selectedFloor: string | number;
    selectedZone: string,
    is_as_soon_as_possible: boolean,
    fmsfc_start_date_for_filter_modal: Date | null, // todo: this should be either a date or a string, not both
    End_Date_For_filter_modal: string | Date, // todo: this should be either a date or a string, not both
    isZoneDisabled: boolean,
    // minTime: Date,

    av: boolean,
    presentationAids: boolean,
    hearingAids: boolean,
    catering: boolean,
    linkedSpace: boolean,
    layouts: boolean,
    numberOfPeople: string,
    periodTypeOption: Array<OptionNumType>,
    selectedperiodType: number,
}

class IbssSpaceAnalyticsDetailCriteria extends Component<any, SearchCriteriaState>
{
    private labels = appContext().labels;
    private session = appContext().sessionStorageProvider;
    private local = appContext().localStorageProvider;
    private history: History.History;
    private defaultMonthStartDate = this.getFirstDayOfMonthForCurrentDate().toJSDate();
    private defaultEndDate = this.getlastDayOfMonthForCurrentDate().toJSDate();

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.state = {
            buildingOptions: [],
            workSpaceTypeOptions: [],
            SpaceTypeOptions: [],
            floorTypeOptions: [],
            zoneOptions: [],
            selectedBuildingOption: "",
            selectedWorkspaceTypes: "",
            selectedSpaceTypes: "",
            selectedFloor: "",
            selectedZone: "",
            is_as_soon_as_possible: false,
            fmsfc_start_date_for_filter_modal: null,
            End_Date_For_filter_modal: "",
            isZoneDisabled: false,
            av: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
            numberOfPeople: '',
            periodTypeOption: [],
            selectedperiodType: 1
        }
    }

    public async componentDidMount() {
        const periodType = [
            { label: `${this.labels.HublabelDay}`, value: 3 },
            { label: `${this.labels.HublabelWeek}`, value: 0 },
            { label: `${this.labels.HublabelMonth}`, value: 1 },
            { label: `${this.labels.HublabelYear}`, value: 2 },
        ];
        const bldgOpts = this.makeBuildingOptions(getAllBuildingsData());
        this.setState({
            buildingOptions: bldgOpts,
            periodTypeOption: periodType,
        });
        await this.setState({
            selectedperiodType: this.props.onelensSpaceAnalyticsDetails.periodType,
            selectedBuildingOption: this.props.onelensSpaceAnalyticsOverview.buildingNodeId,
            fmsfc_start_date_for_filter_modal: this.props.onelensSpaceAnalyticsDetails.startDate,
            End_Date_For_filter_modal: this.props.onelensSpaceAnalyticsDetails.endDate
        });

    }

    public makeBuildingOptions(data: any) {
        let options: any = [];
        data.map((obj: any) => {
            options.push({ label: obj['Name'], value: obj['Node_Id'] })
        });
        return options;
    }

    public makeSpaceTypeOptions(data: any) {
        let options: any = [];
        const spacesSet = new Set();
        data.map((obj: any) => {
            if (!spacesSet.has(obj['Name'])) {
                options.push({ label: obj['Label'], value: obj['Name'] })
                spacesSet.add(obj['Name'])
            }
        });
        options.unshift({ label: "Any", value: "Any" })
        this.setState({
            SpaceTypeOptions: options,
            selectedSpaceTypes: options[1].value,
        });
    }
    /*Filter calendar according to type day, month, year*/
    public getCalendarView(selectedPeriodType: number) {
        let view: CalendarPickerView = 'month';
        if (this.state.selectedperiodType === 1) {
            view = view;
        } else if (this.state.selectedperiodType === 2) {
            view = "year";
        } else if (this.state.selectedperiodType === 3) {
            view = "day"
        } else {
            view = "day"
        }
        return view;
    };


    public async handleOnBldngChange(event: SelectChangeEvent) {
        await this.setState({
            selectedBuildingOption: event.target.value
        });
        this.updateBlndgDependentParams();

    };


    public handleOnWorkspaceTypeChange(event: SelectChangeEvent) {
        this.setState({
            selectedWorkspaceTypes: event.target.value,
            selectedSpaceTypes: "Any"
        });
    };



    /* Get current week monday */
    public getMondayOfCurrentWeek() {
        const today = DateTime.local();
        const monday = today.startOf('week');
        const lastday = monday.plus({ days: 6 });
        this.setState({ fmsfc_start_date_for_filter_modal: monday.toJSDate() });
        this.setState({ End_Date_For_filter_modal: lastday.toJSDate() })
    }

    public handlePeriodTypeChange(event: SelectChangeEvent) {
        let period = "Month";
        this.setState({
            selectedperiodType: parseInt(event.target.value)
        })
        if (Number(event.target.value) === 2) {
            this.setState({ fmsfc_start_date_for_filter_modal: this.getFirstDayOfYearForCurrentDate().toJSDate() });
            this.setState({ End_Date_For_filter_modal: this.getLastDayOfYearForCurrentDate() })
        } else if (Number(event.target.value) === 3) {
            this.setState({ fmsfc_start_date_for_filter_modal: DateTime.local().minus({ days: 1 }).toJSDate() });
            this.setState({ End_Date_For_filter_modal: DateHelper.today().endOf('day').toISO() })
        } else if (Number(event.target.value) === 0) {
            this.getMondayOfCurrentWeek();
        } else {
            this.setState({ fmsfc_start_date_for_filter_modal: this.getFirstDayOfMonthForCurrentDate().toJSDate() });
            this.setState({ End_Date_For_filter_modal: this.getlastDayOfMonthForCurrentDate().toISO() })
        }
    }

    public handleChangeCustomFilterEndDate(e: any) {
        this.setState({
            End_Date_For_filter_modal: e,
        })
    }

    public clear(): void {
        this.props.dispatch({
            type: ONELENS_SPACEANALYTICS_DETAIL, payload: {
                spaceName: this.props.match.params.spaceName,
                periodType: this.props.onelensDateBuilding.periodtype_,
                startDate: this.props.onelensDateBuilding.periodstartDate,
                endDate: this.props.onelensDateBuilding.periodEndDate,
            }
        });
        this.setState({
            selectedperiodType: this.props.onelensDateBuilding.periodtype_,
            selectedBuildingOption: this.props.onelensSpaceAnalyticsOverview.buildingNodeId,
            fmsfc_start_date_for_filter_modal: this.props.onelensDateBuilding.periodstartDate,
            End_Date_For_filter_modal: this.props.onelensDateBuilding.periodEndDate
        });
        const filterData = { buildingNodeId: this.props.onelensDateBuilding.buildingNodeId, buildingOption: this.props.onelensSpaceAnalyticsOverview.buildingOption, endDate: this.props.onelensDateBuilding.periodEndDate.toString(), periodType: this.props.onelensDateBuilding.periodtype_, periodTypeValue: this.props.onelensSpaceAnalyticsOverview.periodTypeValue, spaceName: this.props.onelensSpaceAnalyticsDetails.spaceName, startDate: this.props.onelensDateBuilding.periodstartDate.toString() };
        this.props.updateSearchResults(this.props.onelensSpaceAnalyticsOverview.buildingNodeId, filterData);
    }

    public updateBlndgDependentParams() {

        if (this.state.selectedBuildingOption != undefined && this.state.selectedBuildingOption != null && this.state.selectedBuildingOption != '') {

            this.setState({
                fmsfc_start_date_for_filter_modal: this.defaultMonthStartDate,
                End_Date_For_filter_modal: this.defaultEndDate
            });
        }
    }

    public selectedMondays = (day: any) => {
        if (this.state.selectedperiodType === 0) {
            return day.$d.getDay() !== 1;
        }
        return false;
    };

    /* Start_Date & End_Date change as per selection */
    public handleStartDate = (e: any) => {
        const startDay = e.$d;
        if (this.state.selectedperiodType === 2) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: this.getLastDayOfYearForCurrentDate(startDay.toISOString()),
            });
        } else if (this.state.selectedperiodType === 1) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: this.getlastDayOfMonthForCurrentDate(startDay.toISOString()).toISO(),
            });
        } else if (this.state.selectedperiodType === 0) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: this.weekEndDayForCurrentDate(startDay.toISOString()).toISO(),
            });
        } else if (this.state.selectedperiodType === 3) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: DateTime.fromISO(startDay.toISOString()).plus({ days: 1 }).toISO(),
            });
        }
    };

    public async upDateSearchResults() {
        let buildingId = null;
        let filterData: any = {};

        if (this.state.selectedBuildingOption != undefined && this.state.selectedBuildingOption != null && this.state.selectedBuildingOption != '') {
            buildingId = parseInt(this.state.selectedBuildingOption as string);
            const buildingName = this.state.buildingOptions.filter((val: { value: number }) => val.value === +this.state.selectedBuildingOption);
            filterData = { ...filterData, buildingOption: buildingName[0].label, buildingNodeId: this.state.selectedBuildingOption };
        }
        else {
            //set the default value from user prefs
            let buildingId = this.session.getBuildingId();
            filterData = { ...filterData, buildingOption: buildingId };
        }

        if (this.state.selectedperiodType || this.state.selectedperiodType === 0) {
            filterData = {
                ...filterData, periodTypeValue: this.state.selectedperiodType === 1 ? "Month" : this.state.selectedperiodType === 2 ? "Year" : this.state.selectedperiodType === 3 ? "Day" : this.state.selectedperiodType === 0 ? "Week" : "",
                periodType: this.state.selectedperiodType,
            }
        }
        if (this.state.fmsfc_start_date_for_filter_modal && this.state.End_Date_For_filter_modal) {
            filterData = { ...filterData, startDate: this.state.fmsfc_start_date_for_filter_modal.toISOString(), endDate: this.state.End_Date_For_filter_modal.toString(), spaceName: this.props.match.params.spaceId }
        }
        await this.props.dispatch({ type: ONELENS_SPACEANALYTICS_DETAIL, payload: filterData });
        await this.props.updateSearchResults(buildingId, filterData)
        this.props.toggleDrawer();
    }

    private getFirstDayOfMonthForCurrentDate(date?: string): DateTime {
        if (date) {
            return DateTime.fromISO(date).startOf('month')
        }
        return DateHelper.today().startOf('month');
    }

    private getlastDayOfMonthForCurrentDate(date?: string, timeZone?: string): DateTime {
        const now = DateHelper.now();
        let { year, month } = now;
        const endOfMonth = DateTime.local(year, month, 1).endOf('month');
        const endOfCurrentMonth = endOfMonth.startOf('day');
        if (date) {
            return DateTime.fromISO((DateTime.fromISO(date).plus({ months: 1 }).startOf('month').minus({ days: 1 }).toJSDate()).toISOString())
        }
        return DateTime.fromISO((endOfCurrentMonth.toJSDate()).toISOString()).setZone(timeZone);
    }

    private getFirstDayOfYearForCurrentDate(): DateTime {
        return DateHelper.today().startOf('year');
    }

    private getLastDayOfYearForCurrentDate(date?: string, timeZone?: string): string {
        if (date) {
            return DateTime.fromObject({ year: new Date(date).getFullYear(), month: 12, day: 31 }).toUTC().toString();
        }
        return DateHelper.today().set({ month: 12, day: 31 }).toString();
    }

    private weekEndDayForCurrentDate(date: string): DateTime {
        return DateTime.fromISO(date).plus({ day: 6 }).endOf('day');
    }

    public render() {
        const { open, toggleDrawer }: any = this.props;

        return (

            <>
                <Drawer open={open} onClose={() => toggleDrawer()} direction='right' className='flex-search-filter-criteria' style={{ backgroundColor: "var(--ui-background-alternate)" }}>
                    <div className="flexMySearch-filter-criteria">
                        <div className="flexMySearch-filter-criteria-header">
                            <span className="flexMySearch-filter-criteria-icon">
                                <img className="flexMySearch-filter-criteria-img " src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Filter-2.svg`} alt="icon" />

                            </span>
                            <span className="flexMySearch-filter-criteria-title">{this.labels.HubLabelflexFilterSearchCritera} </span>
                            <span className="flexMySearch-filter-criteria-close" onClick={() => toggleDrawer()}>&times;</span>
                        </div>


                        <div className="flexMySearch-filter-criteria-content">

                            {/* date and time */}
                            <div className="flexMySearch-filter-criteria-firstLabel">
                                {this.labels.HubLabelDateandTime}
                            </div>
                            {/* Period Type */}
                            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                <div className="col-5 card pt-0 ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HublabelPeriodType}</div>
                                <div className="col-7 card pt-0 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={this.state.selectedperiodType}
                                            onChange={(e: any) => this.handlePeriodTypeChange(e)}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                            {this.state.periodTypeOption.map((eachVal: any) => {
                                                return (
                                                    <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                )
                                            })}
                                        </Select>

                                    </FormControl>

                                </div>
                            </div>
                            <div className="row flexMySearch-filter-criteria-selectBldng-filter">

                                <div className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HublabelPeriodStartDate}</div>
                                <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                    <FormControl fullWidth size="small">
                                        <IbssDatePicker
                                            value={this.state.fmsfc_start_date_for_filter_modal}
                                            onChange={(e) => this.handleStartDate(e)}
                                            renderInput={(props) => <TextField {...props} size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                                            shouldDisableDate={this.selectedMondays}
                                            views={[this.getCalendarView(this.state.selectedperiodType)]}
                                            />
                                    </FormControl>
                                </div>
                            </div>
                            <div className="flexMySearch-filter-criteria-border" />

                            {/* buttons */}
                            <div className="right-space-box-cont">
                                <div className="d-flex justify-content-center">
                                    <a type="button" className="clear-attendees my-2" onClick={() => { this.clear(); this.upDateSearchResults() }}>{this.labels.HubLabelClearSelections}</a>
                                    <span className="ml-2">
                                        <IbssButtonRedo
                                            variant="contained"
                                            onClick={() => this.upDateSearchResults()}
                                        >
                                            {this.labels.HubButtonUpdate}
                                        </IbssButtonRedo>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
        onelensSpaceAnalyticsOverview: state.onelensSpaceAnalyticsOverview,
        onelensDatePeriodType: state.onelensDateBuilding.periodtype_,
        onelensDateBuilding: state.onelensDateBuilding
    };
};

export default withRouter(connect(mapStateToProps)(IbssSpaceAnalyticsDetailCriteria));