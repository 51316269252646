import React, { Component } from 'react'
import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { appContext } from '../../../../AppContext';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IFilterOptions, IFilterOptionsNumber } from './EditAdvancedSpace.tsx';


export default class Location extends Component<ILocation> 
{
  private get labels() { return appContext().labels; }
 

  render(): JSX.Element
  {
    const { floorError,isCreate,floorSelected,selectedBuildingOption ,floorTypeOptions, selectedFloor, onFloorChange, zoneOptions, selectedZone, onZoneChange, xCoordinates, onXCoordinatesChange, yCoordinates, onYCoordinatesChange} = this.props;
    
    return (
        <Box mt={2} className="tablePanel" sx={{'& > :not(style)': { my: 1},}}>
            <Box mt={2} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} sx={{'& > :not(style)': { mt: 1},}}>
                    <Box className="icon-bg-dark" mr={1} p={1.5}>
                        <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                            {Icons.LocationIcon}
                        </IbssSvgIcon>
                    </Box>
                    <Typography variant="h6" sx={{fontWeight:'regular'}}>{this.labels.HubLabelLocation}</Typography>
                </Box>
            </Box>
            <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
                <IbssInputDropDown 
                    required
                    options={floorTypeOptions}
                    id="Floors"
                    disabled={selectedBuildingOption == "" && isCreate}
                    value={selectedFloor}
                    inputLabel={this.labels.HubMenuFloorLabel}
                    fullWidth
                    error={floorError}
                    onChange={(event: SelectChangeEvent<string>) => onFloorChange(event)}
                />
                <IbssInputDropDown 
                    required
                    options={zoneOptions}
                    id="Zones"
                    value={selectedZone}
                    inputLabel={this.labels.HubMenuZones}
                    fullWidth
                    disabled={!floorSelected}
                    onChange={(event: SelectChangeEvent<string>) => onZoneChange(event)}
                />
            </Box>
            <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
                <IbssTextField 
                    fullWidth 
                    value={xCoordinates}
                    label={this.labels.HubLabelXCoordinates}
                    variant="outlined" 
                    type="number"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onXCoordinatesChange(event)}
                />
                <IbssTextField 
                    fullWidth 
                    value={yCoordinates} 
                    label={this.labels.HubLabelYCoordinates}
                    variant="outlined" 
                    type="number"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onYCoordinatesChange(event)}
                />
            </Box>
        </Box>
    )
  }
}


interface ILocation 
{
    floorTypeOptions:IFilterOptionsNumber[];
    selectedFloor: number;
    onFloorChange: (event: SelectChangeEvent<string>) => void; 
    zoneOptions: IFilterOptions[];
    selectedZone: string;
    onZoneChange: (event: SelectChangeEvent<string>) => void; 
    xCoordinates: number;
    onXCoordinatesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    yCoordinates: number;
    onYCoordinatesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedBuildingOption: string;
    floorError: boolean;
    floorSelected: boolean;
    isCreate: boolean;
}