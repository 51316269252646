import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import Helper, { dateConverter, getBuildingTimeZoneByNodeId } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link, RouteComponentProps, RouterProps, generatePath } from "react-router-dom";
import { IPartialAppState, appContext } from "../../../../AppContext";
import { DateHelper } from "../../../../Common/DateHelper";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import IbssPageHeader from "../../../../Components/Forms/DateRange/IbssPageHeader";
import { DateTime } from "luxon";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import AddIcon from '@mui/icons-material/Add';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, Button, Grid, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import ApiMessageBuilder from "../../../../Providers.Api/ApiMessageBuilder";
import { IDispatch, IPropsFromState } from "../../../../redux/Interfaces";
import PrintPass from "../../../../Components/Obsolete/PrintPass";
import IbssAutocomplete from "../../../../Components/Inputs/AutoComplete/IbssAutocomplete";
import BookingStatus from "../../../../Components/Miscellaneous/BookingStatus/BookingStatus";
import TimeZoneOffset from "../../../../Components/Miscellaneous/TimeZoneOffset/TimeZoneOffset";
import NewVisitorModal from "./NewVisitorModal";
import { BuildingChangeReason, IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import { ODataQuery } from "../../../../Providers.Api/ODataQuery";
import { Visit2, VisitFilter, VisitStatus } from "../../../../Providers.Api/Visits/VisitsRepository";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import React from "react";
import UserPicker, * as UserPickerModule from "../../../../Components/Inputs/UserPicker/UserPicker";
import RefreshIcon from '@mui/icons-material/Refresh';
import customTheme from "../../../../customTheme";

class ListVisits extends IbssComponent<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get visitsService() { return appContext().visitsService; }
    private get labels() { return appContext().labels; }
    private apiMessageBuilder = new ApiMessageBuilder();
    private visitorPassesContainer = React.createRef<HTMLDivElement>();
    private visitorPasses: React.RefObject<HTMLDivElement>[] = [];
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            canExport: false,
            canImport: false,
            canCreateVisits: false,
            canUpdateVisits: false,
            isRefreshButtonDisabled: false,
            activePage: "0",
            searchTerm: "",
            daysFilter: "",
            visits: [],
            bookingStartDate: DateTime.now(),
            bookingEndDate: DateTime.now(),
            filterStatusOption: [
                { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
                { label: this.labels.HubLabelCancelled, value: "Cancelled" },
                { label: this.labels.HubLabelDeniedLabel, value: "Denied" },
                { label: this.labels.HubLabelApproved, value: "Approved" },
                { label: this.labels.HubLabelCheckedIn, value: "Checked In" },
                { label: this.labels.HubLabelCheckedOut, value: "Checked Out" },
                { label: this.labels.HubLabelNoShow, value: "No Show" },
                { label: this.labels.HubLabelAutoCancelledStatus, value: "Auto Cancelled" },
            ],
            selectedHost: { label: "", email: "" },
            dialogSelectedHost: { label: "", email: "" },
            dialogHostName: "",
            filterValue: "",
            dateDisabled: true,
            selectedVisitIds: [],
            isCompleteLoading: false,
            cancelButtonEnabled: false,
            approveButtonEnabled: false,
            denyButtonEnabled: false,
            checkInButtonEnabled: false,
            checkOutButtonEnabled: false,
            printPassButtonEnabled: false,
            showDeny: false,
            denyComment: "",
            isFileUpload: false,
            file: { name: "" },
            showPrintPass: false,
            showFilterDialog: false,
            showAddVisitorModal: false,
            buildingId: this.appState.buildingId,
            visitStatus: "",
            badgeHtml: '',
        }
    }

    private _isFlex: boolean | null = null;
    private get isFlex(): boolean
    {
        if (this._isFlex == null)
        {
            const path = new URL(window.location.href).pathname;
            this._isFlex = (path == "/flex-my-visitor");
        }
        return this._isFlex;
    }

    private get buildingIdQueryParam(): number
    {
        const buildingIdOrNaN = parseInt(this.props.match.params.buildingid ?? "0");
        const buildingId = (isNaN(buildingIdOrNaN) ? 0 : buildingIdOrNaN);
        return buildingId;
    }

    public async componentDidMount(): Promise<void>
    {
        try{
            const file = await this.apiClient.files.getFileAsBlob('c/Badge/badge.html');
            const htmlText = await file.text();
            this.setState({ badgeHtml: htmlText });
        }
        catch{}

        const query = new URLSearchParams(this.props.location.search);
        this.onBuildingIdChanged<IMatchParams>(params => (params.buildingid ?? ""), (buildingId, reason) => this.buildingIdChanged(buildingId, reason, this.props.location.search));

        if (this.isFlex)
        {
            const currentUser = this.local.getUserDetails();
            const currentUserName = currentUser.displayName || currentUser.email || "";

            this.setState({
                daysFilter: this.labels.HubLabelThisWeek,
                bookingStartDate: DateTime.now().startOf('week'),
                bookingEndDate: DateTime.now().startOf('week').plus({ days: 6 }),
                selectedHost: { label: currentUserName, email: currentUser.email },
                dialogHostName: currentUserName,
            });
        }
        else 
        {
            this.setState({
                daysFilter: this.labels.HubLabelToday,
                bookingStartDate: DateTime.now().date(),
                bookingEndDate: DateTime.now().date(),
                filterValue: query.get('Status') ?? '', // for onelens visitors page, use query string to set state filterValue, which affects visits loaded by status.
            });
        }

        const buildingId = (this.isFlex ? 1 : this.buildingIdQueryParam);
        if (!this.isFlex)
        {
            await this.appState.set({ buildingId: this.buildingIdQueryParam });
        }

        await this.setStateAsync({ activePage: this.props.match.params.tab ?? "" });
        await this.loadRights();
        await this.setBuilding(buildingId);
    }

    private loadRights(): void
    {
        this.setState({
            canExport: this.local.hasRight("API.Visits.Export"),
            canImport: this.local.hasRight("API.Visits.Import"),
            canCreateVisits: this.local.hasRight("DATAMODEL.Visits.Create"),
            canUpdateVisits: this.local.hasRight("DATAMODEL.Visits.Update")
        });
    }

    private buildingIdChanged(buildingId: number, changeReason: BuildingChangeReason, query?: string): Promise<void>
    {
        if (changeReason == "BuildingSelectorChanged")
        {
            const path = generatePath(this.props.match.path, { buildingid: buildingId, tab: this.props.match.params.tab, filter: this.props.match.params.filter });
            const url = `${path}${query ?? ''}` // react router dom path cannot take a query. 
            this.props.history.push(url);
        }
        return this.setBuilding(buildingId);
    }

    private async setBuilding(buildingId: number): Promise<void>
    {
        const building = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(i => i.Node_Id == buildingId) ?? null;
        this.pageTitle = this.labels.HubLabelFacilityManagement;

        if (building != null)
        {
            this.pageTitle += " - " + building.Name;
        }

        await this.setStateAsync({ buildingId: buildingId });
        await this.loadVisits();
    }

    private loadVisitsTimeout: (NodeJS.Timeout | null) = null;
    private queueLoadVisits(): void
    {
        this.setState({ isCompleteLoading: true });
        clearTimeout(this.loadVisitsTimeout ?? undefined);
        this.loadVisitsTimeout = setTimeout(() => this.loadVisits(), 1000);
    }

    private async loadVisits(): Promise<void>
    {
        try
        {
            if (!this.state.bookingStartDate.isValid || !this.state.bookingEndDate.isValid)
            {
                await this.setStateAsync({ visits: [] });
                return;
            }

            const query = new ODataQuery({
                top: 201,
                nodeId: this.state.buildingId,
                select: Visit2,
                filter: new VisitFilter({
                    minStartDate: this.state.bookingStartDate.date().setZoneByNode(this.state.buildingId),
                    maxEndDate: this.state.bookingEndDate.date().plus({ days: 1 }).setZoneByNode(this.state.buildingId),
                    status: this.state.filterValue,
                    hostEmail: this.state.selectedHost.email,
                }),
            });

            const visits = await this.visitsService.getMany(query);
            
            if (visits.value.length > 200)
            {
                this.alert.show("", this.labels.TooManySearchResults_L);
            }

            const visitsView = visits.value.slice(0, 200).map(i => ({ id: i.Visit_Id, ...i }));
            await this.setStateAsync({ visits: visitsView });
        }
        finally
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private get filteredVisits(): Visit2[] 
    {
        const searchTerm = this.state.searchTerm;
        const lowerSearchTerm = searchTerm.toLowerCase();

        const filteredVisits = this.state.visits.filter(visit =>
        {
            let key: keyof Visit2;
            for (key in visit)
            {
                const value = visit[key]?.toString().toLowerCase();
                if (value && value.includes(lowerSearchTerm)) 
                {
                    return true;
                }
            }
            return false;
        });
        return filteredVisits;
    }

    private get selectedVisits(): Visit2[]
    {
        return this.filteredVisits.filter(row => this.state.selectedVisitIds.includes(row.Visit_Id));
    }

    private async selectionChanged(selection: GridRowSelectionModel): Promise<void>
    {
        await this.setStateAsync({ selectedVisitIds: selection });
        this.enableDisableActionButtons();
    }

    private enableDisableActionButtons(): void
    {
        const selectedVisits = this.selectedVisits;
        const cancellableRows = selectedVisits.filter(i => i.Visit_Status == "New" || i.Visit_Status == "Amended" || i.Visit_Status == "Late Checkin" || i.Visit_Status == "Awaiting Approval" || i.Visit_Status == "Approved");
        const approvableRows = selectedVisits.filter(i => i.Visit_Status == "Awaiting Approval");
        const checkInableRows = selectedVisits.filter(i => i.Visit_Status == "Approved");
        const checkOutableRows = selectedVisits.filter(i => i.Visit_Status == "Checked In");
        const printableRows = selectedVisits.filter(i => i.Visit_Status == "Checked In" || i.Visit_Status == "Approved");

        this.setState({
            cancelButtonEnabled: (selectedVisits.length > 0 && selectedVisits.length == cancellableRows.length),
            approveButtonEnabled: (selectedVisits.length > 0 && selectedVisits.length == approvableRows.length),
            denyButtonEnabled: (selectedVisits.length > 0 && selectedVisits.length == approvableRows.length),
            checkInButtonEnabled: (selectedVisits.length > 0 && selectedVisits.length == checkInableRows.length),
            checkOutButtonEnabled: (selectedVisits.length > 0 && selectedVisits.length == checkOutableRows.length),
            printPassButtonEnabled: (selectedVisits.length > 0 && selectedVisits.length == printableRows.length),
        });
    }

    private async cancelManyClicked(): Promise<void>
    {
        this.setState({ isCompleteLoading: true });
        let message = "";
        const selectedVisitsByNodeId: {[key: number]: Visit2[]} = {};
    
        this.selectedVisits.forEach(visit => {
            if (!Object.hasOwnProperty.call(selectedVisitsByNodeId, visit.Node_Id)) 
            {
                selectedVisitsByNodeId[visit.Node_Id] = [];
            }
            selectedVisitsByNodeId[visit.Node_Id].push(visit);
        });
    
        try 
        {
            for (const nodeId in selectedVisitsByNodeId) 
            {
                if (Object.hasOwnProperty.call(selectedVisitsByNodeId, nodeId)) 
                {
                    const selectedVisitIdsForNodeId = selectedVisitsByNodeId[nodeId].map(x => x.Visit_Id)
                    await this.visitsService.cancel(Number(nodeId), selectedVisitIdsForNodeId);
                }
            }
            await this.loadVisits();
            this.enableDisableActionButtons();
            this.alert.show("", message);
        }
        finally
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private async approveVisitorClicked(): Promise<void>
    {
        this.setState({ isCompleteLoading: true });
        let message = "";

        const selectedVisitIds = this.selectedVisits.map(x => x.Visit_Id);
        try
        {
            await this.visitsService.approve(this.appState.buildingId, selectedVisitIds, '')
            await this.loadVisits();
            this.enableDisableActionButtons();
            this.alert.show("", message);
        }
        finally
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private async denyVisitorClicked(): Promise<void>
    {
        this.setState({ isCompleteLoading: true });
        let message = "";

        const selectedVisitIds = this.selectedVisits.map(x => x.Visit_Id);

        try
        {
            await this.visitsService.deny(this.appState.buildingId, selectedVisitIds, this.state.denyComment)
            await this.loadVisits();
            this.enableDisableActionButtons();
            this.alert.show("", message);
        }
        finally
        {
            this.setState({ isCompleteLoading: false, showDeny: false });
        }
    }

    private async checkInVisitorClicked(): Promise<void>
    {
        this.setState({ isCompleteLoading: true });
        let message = "";

        const selectedVisitIds = this.selectedVisits.map(x => x.Visit_Id);
        try
        {
            await this.visitsService.checkin(this.appState.buildingId, selectedVisitIds)
            await this.loadVisits();
            this.enableDisableActionButtons();
            this.alert.show("", message);
        }
        finally
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private async checkOutVisitorClicked(): Promise<void>
    {
        this.setState({ isCompleteLoading: true });
        let message = "";

        const selectedVisitIds = this.selectedVisits.map(x => x.Visit_Id)
        try
        {
            await this.visitsService.checkout(this.appState.buildingId, selectedVisitIds)
            await this.loadVisits();
            this.enableDisableActionButtons();
            this.alert.show("", message);
        }
        finally
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private async hostChanged(selectedHost: UserPickerModule.IUser | null): Promise<void>
    {
        this.setState({
            dialogSelectedHost: {
                label: selectedHost?.label || selectedHost?.email || "",
                email: selectedHost?.email ?? "",
            }
        });
    }

    private async dateRangeChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ daysFilter: e.target.value });
        let reloadVisits = true;

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setStateAsync({
                bookingStartDate: DateTime.now().date(),
                bookingEndDate: DateTime.now().date(),
                dateDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setStateAsync({
                bookingStartDate: DateTime.now().date().plus({ days: 1 }),
                bookingEndDate: DateTime.now().date().plus({ days: 1 }),
                dateDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setStateAsync({
                bookingStartDate: DateTime.now().startOf('week'),
                bookingEndDate: DateTime.now().startOf('week').plus({ days: 6 }),
                dateDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setStateAsync({
                bookingStartDate: DateTime.now().startOf('week').plus({ days: 7 }),
                bookingEndDate: DateTime.now().startOf('week').plus({ days: 13 }),
                dateDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setStateAsync({
                bookingStartDate: DateTime.now().startOf('month'),
                bookingEndDate: DateTime.now().startOf('month').plus({ months: 1, days: -1 }),
                dateDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setStateAsync({
                bookingStartDate: DateTime.now().startOf('month').plus({ months: 1 }),
                bookingEndDate: DateTime.now().startOf('month').plus({ months: 2, days: -1 }),
                dateDisabled: true,
            });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setStateAsync({
                dateDisabled: false
            });
            reloadVisits = false;
        }

        if (reloadVisits)
        {
            this.loadVisits();
        }
    }

    private async startDateChanged(e: DateTime): Promise<void>
    {
        await this.setStateAsync({
            bookingStartDate: e,
            isCompleteLoading: true
        });
        await this.queueLoadVisits();
    }

    private async endDateChanged(e: DateTime): Promise<void>
    {
        await this.setStateAsync({
            bookingEndDate: e,
            isCompleteLoading: true
        });
        await this.queueLoadVisits();
    }

    private denyModelCancelled(): void 
    {
        this.setState({ showDeny: !this.state.showDeny });
    }

    private denyButtonClicked(): void
    {
        this.setState({ showDeny: !this.state.showDeny });
    }

    private fileUploadModalClosed(): void
    {
        this.setState({ isFileUpload: !this.state.isFileUpload });
    }

    private selectedFileChanged(e: React.ChangeEvent<HTMLInputElement>) 
    {
        this.setState({ file: e.target.files?.[0] });
    }

    private async uploadFileButtonClicked(): Promise<void> 
    {
        this.setState({ isCompleteLoading: true });
        this.fileUploadModalClosed();
        const formData = new FormData();

        const file = (this.state.file ? this.state.file : "");
        formData.append('file', file as string);
        formData.append('fileName', (file as IFile).name);

        try
        {
            const res = await apis.visitorImportFile(this.appState.buildingId, formData);
            if (res.status === 200)
            {
                let messageHtml = `<h2>${this.labels.HubLabelFileuploadedsuccessfully}</h2> </br>`;

                const erroredEntries = res.data.filter((x: { ImportSuccessful: boolean; }) => x.ImportSuccessful == false);
                const successfulEntries = res.data.filter((x: { ImportSuccessful: boolean; }) => x.ImportSuccessful == true);

                if (erroredEntries.length > 0)
                {
                    messageHtml = `<h2>${successfulEntries.length}/${res.data.length} ${this.labels.HubLabelEntriesUploadedSuccessfully}</h2><h3>${this.labels.HubLabelIssuesOccured}:</h3>` + res.data.filter((x: { ImportSuccessful: boolean; }) =>
                        x.ImportSuccessful == false).map((i: { Error: { RowNo: number, Message: string } }) =>
                            `<span>${this.labels.HubLabelRow} ${i.Error?.RowNo ? i.Error?.RowNo + 1 : '-'} ${i.Error?.Message?.length > 0 ? `- ${i.Error?.Message}` : ''}</span> </br>`).join('');
                }
                this.alert.show("", messageHtml, () => { }, { messageIsHtml: true });
            }
            else
            {
                this.alert.show("", this.apiMessageBuilder.getMessage(res.statusText, this.appState.buildingId));
            }
        }
        catch (error)
        {
            const typedError = error as { Message: string };
            this.alert.show("", typedError.Message);
        }
        finally
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private async downloadVisitsClicked(): Promise<void> 
    {
        const email = this.local.getUserDetails().email;
        const filteredVisitorsObj = {
            buildingId: this.state.buildingId,
            startDate: this.state.bookingStartDate.date().setZoneByNode(this.state.buildingId).startOf('day').toUTC().toISO(),
            endDate: this.state.bookingEndDate.date().setZoneByNode(this.state.buildingId).endOf('day').toUTC().toISO(),
            statusValue: this.state.filterValue,
            host: this.state.selectedHost.email.toLowerCase(),
            email: email.toLowerCase(),
            type: this.isFlex ? "flex" : "oneLens"
        }
        try
        {
            await apis.downloadExcelFilterVisitors(filteredVisitorsObj);
        }
        catch (error) { }

    }

    private async printPassButtonClicked(): Promise<void>
    {
        this.visitorPasses = this.state.selectedVisitIds.map(() => React.createRef());
        await this.setStateAsync({ showPrintPass: !this.state.showPrintPass });
        const selectedVisitors = this.state.visits.filter(x => this.state.selectedVisitIds.includes(x.Visit_Id));
        
        let imageUrl = '';
        const firstVisitLogo = this.visitorPasses[0].current?.querySelector(`#visit-logo`);
        if (firstVisitLogo)
        {
            const fileBlob = await this.apiClient.files.getFileAsBlob(firstVisitLogo.innerHTML);
            imageUrl = URL.createObjectURL(fileBlob);
        }

        selectedVisitors.forEach(async (visitor, index) =>
        {
            const visitorPass = this.visitorPasses[index].current;
            if (visitorPass == null)
            {
                return;
            }

            const startDate = DateTime.fromISO(visitor.Visit_Start_Date);
            const endDate = DateTime.fromISO(visitor.Visit_End_Date);

            const visitorName = visitorPass.querySelector(`#visitor-name`);
            const visitorCompany = visitorPass.querySelector(`#visitor-company`);
            const visitDate = visitorPass.querySelector(`#visit-date`);
            const visitTime = visitorPass.querySelector(`#visit-time`);
            const visitHost = visitorPass.querySelector(`#visit-host`);
            const visitLogo = visitorPass.querySelector(`#visit-logo`);

            if (visitorName) visitorName.innerHTML = visitor.Visitor_First_Name + ' ' + visitor.Visitor_Last_Name;
            if (visitorCompany) visitorCompany.innerHTML = visitor.Visitor_Company;
            if (visitDate) visitDate.innerHTML = startDate.toLocaleDateString();
            if (visitTime) visitTime.innerHTML = startDate.toLocaleTimeString() + ' - ' + endDate.toLocaleTimeString();
            if (visitHost) visitHost.innerHTML = visitor.Visit_Host_Name;
            if (visitLogo) visitLogo.innerHTML = `<img src=${imageUrl}>`;
        });
    }

    private addVisitButtonClicked(): void
    {
        this.setState({ showAddVisitorModal: true });
    }

    private editVisitButtonClicked(param: Visit2): void
    {
        const { history } = this.props;
        if (this.isFlex)
        {
            history.push(`/flex-my-visitor/${param.Node_Id}/myvisit/${param.Visit_Id}`);
        }
        else
        {
            history.push(`/operational-services-Visitors/${this.appState.buildingId}/visit/${param.Visit_Id}/1`);
        }
    }

    private async filterButtonClicked(): Promise<void>
    {
        await this.setStateAsync({
            showFilterDialog: true,
            dialogHostName: this.state.selectedHost.label,
            dialogSelectedHost: { label: this.state.selectedHost.label, email: this.state.selectedHost.email },
        });
    }

    private filterModalClosed(): void
    {
        this.setState({ showFilterDialog: false });
    }

    private async filterModalOkButtonClicked(): Promise<void>
    {
        try
        {
            await this.setStateAsync({ selectedHost: { label: this.state.dialogSelectedHost.label, email: this.state.dialogSelectedHost.email } });
            await this.loadVisits();
        }
        finally
        {
            this.setState({ showFilterDialog: false });
        }
    }


    private getStartTime(row: Visit2): string
    {
        return DateTime.fromISO(row.Visit_Start_Date).offsetTimeByNode(row.Node_Id, false).toLocaleDateTimeString();
    }

    private getEndTime(row: Visit2): string
    {
        return DateTime.fromISO(row.Visit_End_Date).offsetTimeByNode(row.Node_Id, false).toLocaleDateTimeString();
    }

    private enableRefreshButtonAfterTimeOut(): void
    {
        setTimeout(() => {
            this.setState({ isRefreshButtonDisabled: false });
        }, 60000);
    }

    private async handleRefreshButtonClick(): Promise<void>
    {
        this.setState({ isRefreshButtonDisabled: true });
        await this.loadVisits();
        this.enableRefreshButtonAfterTimeOut();
    }

    public render(): JSX.Element
    {
        const visitColumns: GridColDef[] = [{
            headerName: this.labels.HubLabelVisitStart,
            field: "Booking_Start",
            minWidth: 140,
            flex: 1,
            valueGetter: (params) => this.getStartTime(params.row),
        },
        {
            headerName: this.labels.HubLabelVisitEnd,
            field: "Booking_End",
            minWidth: 140,
            flex: 1,
            valueGetter: (params) => this.getEndTime(params.row),
        },
        {
            headerName: this.labels.HubLabelTimeZone,
            cellClassName:"table-cell-font",
            field: "timeZone",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => <TimeZoneOffset nodeId={params.row.Node_Id} />
        },
        {
            headerName: this.labels.HubLabelVisitorEmail,
            field: Helper.nameOf<Visit2>("Visitor_Email"),
            minWidth: 170,
            flex: 1
        },
        {
            headerName: this.labels.HubLabelvisitor,
            field: "Visitor_Name",
            minWidth: 120,
            flex: 1,
            renderCell: (params) => params.row.Visitor_First_Name + ' ' + params.row.Visitor_Last_Name
        },
        {
            headerName: this.labels.HubLabelCompany,
            field: Helper.nameOf<Visit2>("Visitor_Company"),
            minWidth: 140,
            flex: 1
        },
        {
            headerName: this.labels.HubLabelHostName,
            field: Helper.nameOf<Visit2>("Visit_Host_Name"),
            minWidth: 160,
            flex: 1
        },
        {
            headerName: this.labels.HubLabelVisitID,
            field: Helper.nameOf<Visit2>("Visit_Id"),
            minWidth: 120,
            flex: 1
        },
        {
            headerName: this.labels.HubLabelAction,
            minWidth: 100,
            flex: 1,
            field: "",
            filterable: false,
            sortable: false,
            renderCell: (params) => this.renderEdit(params.row)
        },
        {
            headerName: this.labels.HubLabelStatus,
            field: "Visit_Status",
            minWidth: 140,
            flex: 1,
            renderCell: (params) => <BookingStatus status={params.row.Visit_Status} />,
            valueGetter: (params) => params.row.Visit_Status,
        }];

        if (this.isFlex)
        {
            visitColumns.splice(3, 1,
                {
                    headerName: this.labels.HubLabelSpace,
                    field: Helper.nameOf<Visit2>("Space_Name"),
                    minWidth: 140,
                    flex: 1
                })
            visitColumns.splice(6, 1);
        }

        let actions: IActionButton[] = [
        {
            label: this.labels.funcRefreshBookings_S,
            icon: (
                <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                    <RefreshIcon />
                </IbssSvgIcon>),
            color: "inherit",
            labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
            onClick: () => this.handleRefreshButtonClick(),
            disabled: this.state.isRefreshButtonDisabled
        },
        {
            label: this.labels.HubButtonApprove,
            icon: (
                <IbssSvgIcon>
                    <CheckCircleIcon />
                </IbssSvgIcon>),
            color: "primary",
            disabled: !this.state.approveButtonEnabled || !this.state.canUpdateVisits,
            onClick: () => this.approveVisitorClicked()
        },
        {
            label: this.labels.HubButtonDeny,
            icon: (
                <IbssSvgIcon>
                    <CloseIcon />
                </IbssSvgIcon>
            ),
            color: "primary",
            disabled: !this.state.denyButtonEnabled || !this.state.canUpdateVisits,
            onClick: () => this.denyButtonClicked()
        },
        {
            label: this.labels.HubButtonCancel,
            icon: (
                <IbssSvgIcon>
                    <CloseIcon />
                </IbssSvgIcon>),
            color: "error",
            disabled: !this.state.cancelButtonEnabled || !this.state.canUpdateVisits,
            onClick: () => this.cancelManyClicked(),
        },
        {
            label: this.labels.HubButtonCheckIn,
            icon: (
                <IbssSvgIcon>
                    <CheckCircleIcon />
                </IbssSvgIcon>),
            color: "primary",
            disabled: !this.state.checkInButtonEnabled || !this.state.canUpdateVisits,
            onClick: () => this.checkInVisitorClicked()
        },
        {
            label: this.labels.HubButtonCheckOut,
            icon: (
                <IbssSvgIcon>
                    <CancelIcon />
                </IbssSvgIcon>),
            color: "primary",
            disabled: !this.state.checkOutButtonEnabled || !this.state.canUpdateVisits,
            onClick: () => this.checkOutVisitorClicked()
        },
        {
            label: this.labels.HubButtonPrintPass,
            icon: (
                <IbssSvgIcon>
                    <AccountBalanceWalletIcon />
                </IbssSvgIcon>),
            color: "primary",
            disabled: !this.state.printPassButtonEnabled,
            onClick: () => this.printPassButtonClicked()
        },
        {
            label: this.labels.HubButtonAdd,
            icon: (
                <IbssSvgIcon>
                    <AddIcon />
                </IbssSvgIcon>
            ),
            color: "primary",
            disabled: !this.state.canCreateVisits,
            onClick: () => this.addVisitButtonClicked(),
        }];

        if (this.isFlex)
        {
            actions = actions.filter(i => i.label == this.labels.HubButtonCancel || i.label == this.labels.HubButtonAdd || i.label == this.labels.funcRefreshBookings_S);
        }

        return (
            <>
                <div className="">
                    {this.state.isCompleteLoading && <Spinner />}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="filter visitors modal"
                        onClose={() => this.filterModalClosed()}
                        open={this.state.showFilterDialog}
                        header={this.labels.HubLabelFilter}
                        fullWidth
                        content={
                            <>
                                <div className="row">
                                    <div className="form-input-box">
                                        <label className="form-input-box-label visit-filter-label">{this.labels.HubLabelStatus}</label>
                                        <div className="form-input-select-box">
                                            <select className="custom-form-control visit-filter-selectbox select-box-border" onChange={(e) => this.setState({ filterValue: e.target.value })} value={this.state.filterValue} >
                                                <option></option>
                                                {this.state.filterStatusOption.map((option: IFilterStatus) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {!this.isFlex &&
                                <div className="row">
                                    <div className="form-input-box">
                                        <label className="form-input-box-label visit-filter-label">{this.labels.HublabelHostVisitFilter}</label>
                                        <UserPicker
                                            width="calc(100% - 110px)"
                                            searchText={this.state.dialogHostName}
                                            label=""
                                            placeholder="&#8230;"
                                            alternateStyle={true}
                                            onChange={async text => await this.setStateAsync({ dialogHostName: text })}
                                            onUserChange={user => this.hostChanged(user)}
                                        />
                                    </div>
                                </div>
                                }
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalClosed()}
                                    color="secondary"
                                    variant="outlined"
                                    className="mr-2"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    className="ml-2"
                                    onClick={() => this.filterModalOkButtonClicked()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <IbssDialog
                        aria-modal="true"
                        aria-label="import visitors modal"
                        onClose={() => this.fileUploadModalClosed()}
                        open={this.state.isFileUpload}
                        header={this.labels.HublabelUploadFile}
                        fullWidth
                        content={
                            <>
                                <div className="row mt-3">
                                    <div className="col-3 pl-0 ">
                                        <label className="upload-file-modal-label pr-0">{this.labels.HubLabelFile}</label>
                                    </div>
                                    <div className="col-8 pl-0 file-picker">
                                        <input className="upload-file-modal-input" value={this.state.file?.name} type="text" readOnly></input>
                                        <label htmlFor="filePicker" className="button-outline file-picker-label mr-1">{this.labels.HubLabeSelectFile}</label>
                                        <input id="filePicker" className="hideInput" type={"file"} onChange={(e) => this.selectedFileChanged(e)}></input>
                                    </div>
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <div className="d-flex justify-content-center w-100">
                                    <IbssButtonRedo
                                        onClick={() => this.fileUploadModalClosed()}
                                        color="secondary"
                                        variant="outlined"
                                        className="mr-2"
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        className="ml-2"
                                        onClick={() => this.uploadFileButtonClicked()}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButtonRedo>
                                </div>
                            </>
                        }
                    />
                    <IbssDialog
                        aria-modal="true"
                        aria-label="deny modal"
                        onClose={() => this.denyModelCancelled()}
                        open={this.state.showDeny}
                        header={this.labels.HubButtonDeny}
                        fullWidth
                        content={
                            <>
                                <div className="col-12">
                                    <label>{this.labels.HubLabelComment}</label>
                                    <input className="input-box border model-textbox" type="text" name="denyComment" value={this.state.denyComment} onChange={(e) => this.setState({ denyComment: e.target.value })} />
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    color="secondary"
                                    variant="outlined"
                                    className="mr-2" onClick={() => this.denyModelCancelled()}>{this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disabled={!this.state.denyComment}
                                    onClick={() => this.denyVisitorClicked()}>{this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {/* --Print pass modal-- */}
                    <IbssDialog
                        aria-modal="true"
                        aria-label="print pass modal"
                        onClose={() => this.setState({ showPrintPass: !this.state.showPrintPass })}
                        fullWidth
                        open={this.state.showPrintPass}
                        content={
                            <>
                                <div ref={this.visitorPassesContainer}>
                                    {
                                        this.state.selectedVisitIds.map((id, index) => (
                                            <div ref={this.visitorPasses[index]} dangerouslySetInnerHTML={{ __html: this.state.badgeHtml }}></div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    color="secondary"
                                    variant="outlined"
                                    className="mr-2" onClick={() => this.setState({ showPrintPass: !this.state.showPrintPass })}>{this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <ReactToPrint
                                    content={() => this.visitorPassesContainer.current}
                                    onAfterPrint={() => this.setState({ showPrintPass: false })}
                                    removeAfterPrint
                                >
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <IbssButtonRedo
                                                color="primary"
                                                variant="contained"
                                                size="medium"
                                                className="ml-2" onClick={handlePrint}>{this.labels.HubButtonPrint}
                                            </IbssButtonRedo>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>
                            </>
                        }
                    />
                    <NewVisitorModal
                        showAddVisitorModal={this.state.showAddVisitorModal}
                        closeClicked={() => this.setState({ showAddVisitorModal: !this.state.showAddVisitorModal })}
                    />
                    <div className="page-height-exct-header">
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                <IbssPageHeader
                                    pageTitle={ this.isFlex ? this.labels.HubLabelMyVisitors : this.labels.funcVisitors_S }
                                    daysFilter={this.state.daysFilter}
                                    todayChanged={(e) => this.dateRangeChanged(e)}
                                    startDate={this.state.bookingStartDate.toJSDate()}
                                    endDate={this.state.bookingEndDate.toJSDate()}
                                    dateDisabled={this.state.dateDisabled}
                                    onStartDateChange={i => this.startDateChanged(i)}
                                    onEndDateChange={i => this.endDateChanged(i)}
                                    additionalDateFilterOptions={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                />
                                <Grid container rowSpacing={1} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box
                                            sx={{
                                                '& > :not(style)': { mr: 2, my: 1, alignItems: "center" },
                                            }}
                                        >
                                            <IbssFormControl variant="outlined">
                                                <IbssTextField
                                                    type="text"
                                                    size="small"
                                                    placeholder={this.labels.HubLabelSearch}
                                                    value={this.state.searchTerm}
                                                    onChange={(event) =>
                                                        this.setState({ searchTerm: event.target.value })
                                                    }
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </IbssFormControl>
                                            <IbssButtonRedo color="primary" className="mr-2" variant="contained" onClick={() => this.filterButtonClicked()}>{this.labels.HubLabelFilter}</IbssButtonRedo>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
                                        {this.state.canExport && <IbssButtonRedo color="secondary" className="mr-2" variant="contained" onClick={() => this.downloadVisitsClicked()} >{this.labels.HubButtonExport}</IbssButtonRedo>}
                                        {this.state.canImport && <IbssButtonRedo color="secondary" variant="contained" onClick={() => this.fileUploadModalClosed()}>{this.labels.HubButtonImport}</IbssButtonRedo>}
                                    </Grid>
                                </Grid>
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                    <IbssActionButton buttons={actions} />
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    <IbssDataGrid
                                        checkboxSelection
                                        rows={this.filteredVisits}
                                        columns={visitColumns}
                                        onRowSelectionModelChange={e => this.selectionChanged(e)}
                                        rowSelectionModel={this.state.selectedVisitIds}
                                        initialState={{
                                            pagination: { paginationModel: {pageSize: 25} },
                                        }}
                                        pageSizeOptions={[25,50,100]}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderEdit(row: Visit2): React.ReactNode
    {
        return (
            this.state.canUpdateVisits && !(row.Visit_Status === "Cancelled" || row.Visit_Status === "Completed" || row.Visit_Status === "No Show" || row.Visit_Status === "Auto Cancelled") ?
            <Button onClick={() => this.editVisitButtonClicked(row)}>
                        <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.primary }} className="pointer">
                            <CreateIcon />
                        </IbssSvgIcon> 
            </Button>: <p>-</p>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

export interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

export interface IMatchParams
{
    buildingid?: string;
    tab?: string;
    filter?: string;
}

export interface IState
{
    canExport: boolean;
    canImport: boolean;
    canCreateVisits: boolean;
    canUpdateVisits: boolean;
    isRefreshButtonDisabled: boolean;
    activePage: string;
    searchTerm: string;
    daysFilter: string;
    bookingStartDate: DateTime;
    bookingEndDate: DateTime;
    dateDisabled: boolean;
    selectedVisitIds: GridRowSelectionModel;
    visits: Visit2[];
    isCompleteLoading: boolean;
    cancelButtonEnabled: boolean;
    approveButtonEnabled: boolean;
    denyButtonEnabled: boolean;
    checkInButtonEnabled: boolean;
    checkOutButtonEnabled: boolean;
    printPassButtonEnabled: boolean;
    showDeny: boolean;
    denyComment: string;
    isFileUpload: boolean;
    showPrintPass: boolean;
    file: IFile | undefined;
    filterStatusOption: IFilterStatus[];
    filterValue: string;
    showFilterDialog: boolean;
    selectedHost: IHost;
    dialogSelectedHost: IHost;
    dialogHostName: string;
    showAddVisitorModal: boolean;
    buildingId: number;
    visitStatus: string;
    badgeHtml: string;
}

export interface IFilterStatus
{
    label: string;
    value: string;
}

export interface IHost
{
    label: string;
    email: string;
}

export interface IFile
{
    name: string;
}

export default connect(mapStateToProps)(ListVisits);
