import React, { useState, useEffect } from 'react';
import '../../../../styles/css/table.scss';
import '../../../../App.css';
import { connect, Omit } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import apis from '../../../../Providers.Api/apis';
import IbssbreadcrumbLeft from '../../../../Components/Navigation/Breadcrumbs/IbssbreadcrumbLeft';
import SearchCriteria from './SearchCriteria';
import SpaceAnalyticsTableDash from './SpaceAnalyticsTableDash';
import Helper, { classSapceCall, periodTypeCall, classCapacityCall, spaceDiff, getPeriodTypeString } from '../../../../../src/Common/Helper';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import { appContext } from '../../../../AppContext';
import { ONELENS_SPACEANALYTICS_FILTER, ONE_LENSE_DATE_BULDING, UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';
import SpaceAnalyticsLeftHeader from './SpaceAnalyticsLeftHeader';
import SpaceAnalyticsRightHeader from './SpaceAnalyticsRightHeader';
import { defaultNodeLevel, periodTypeMonth, defaultAny, classTypeDefault, workspaceTypeDefault, space_Kpi_Target, capacity_Kpi_Target, constTotal } from '../../../../app/constants';
import IbssButtonRedo from '../../../../Components/Buttons/Button/IbssButton';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { ISpaceUtilisationSummary } from '../../../../Providers.Api/SpaceUtilisationSummaries/GetManyEndpoint';
import { DateHelper } from '../../../../Common/DateHelper';

const ViewSpaceUtilisation = (props: JSX.IntrinsicAttributes & Omit<any, "dispatch" | "lightModeTheme">) =>
{
    const spaceUtilisationSummaryService = appContext().spaceUtilisationSummaryService;
    const { buildingNodeId: NodeID, buildingname: builidngroutename, classtype: worktype, periodtype, startdate, enddate, floortype, workspaceType, groupby, metabookable } = props.location.state;
    const session = appContext().sessionStorageProvider;
    const buildingid = session.getBuildingId();
    const dispatch_ = useDispatch();
    const labels = appContext().labels;
    const date = DateTime.local();
    const startDate = date.startOf('month');
    const defaultMonthStartDate = startDate.minus({ months: 1 }).startOf('month');
    const defaultMonthEndDate = startDate.minus({ months: 1 }).endOf('month');
    const monthFirstStartDate = date.day !== 1 ? startDate : defaultMonthStartDate;
    const monthFirstEndDate = date.day !== 1 ? startDate.endOf('month') : defaultMonthEndDate.endOf('month');
    const searchData = ["building", "floor", "classType", "workSpace", "periodType", "date"];
    const arrIcon = ["Building", "Floor", "Desk", "Desk", "Calendar", "Calendar"];
    const spaceAnalyticsFilter = session.getSpaceAnalyticsCriteria();

    const { onelensSpaceAnalyticsFilterCriteria,
        buildingNodeId,
        buildingPeriodType,
        buildingClassType,
        buildingWorkSpaceType,
        buildingOptions,
        buildingFloor,
        onelensDateBuilding,
        buildingnodelevelName,
        lightModeTheme
    } = useSelector((state: RootStateOrAny) => ({
        onelensSpaceAnalyticsFilterCriteria: state.onelensSpaceAnalyticsFilterCriteria,
        buildingNodeId: state.onelensDateBuilding.buildingNodeId,
        buildingPeriodType: state.onelensSpaceAnalyticsFilterCriteria.buildingPeriodType,
        buildingClassType: state.onelensSpaceAnalyticsFilterCriteria.buildingClassType,
        buildingWorkSpaceType: state.onelensSpaceAnalyticsFilterCriteria.buildingWorkSpaceType,
        buildingOptions: state.onelensSpaceAnalyticsFilterCriteria.buildingOptions,
        buildingFloor: state.onelensSpaceAnalyticsFilterCriteria.buildingFloor,
        buildingnodelevelName: state.onelensSpaceAnalyticsFilterCriteria.nodeLevelName,
        onelensDateBuilding: state.onelensDateBuilding,
        lightModeTheme: state.lightModeTheme
    }));

    const [width, setWidth] = useState(window.innerWidth);
    const [isLoading, setisLoading] = useState<boolean>(true);
    const [openDrawer, setopenDrawer] = useState<boolean>(false);
    const [spaceAnalyticsResultHeader, setSpaceAnalyticsResultHeader] = useState<{ Period_Current_Space_Value: number; Period_Current_Capacity_Value: number }>({ Period_Current_Space_Value: 0, Period_Current_Capacity_Value: 0 });
    const [spaceKpiTarget, setSpaceKpiTarget] = useState<{ Parameter_Value?: number }>({ Parameter_Value: 0 });
    const [capacityKpiTarget, setCapaityKpiTarget] = useState<{ Parameter_Value?: number }>({ Parameter_Value: 0 });
    const [getbreadcrumbSearchData, setGetbreadcrumbSearchData] = useState([]);
    const [spaceAnalyticsTable, setspaceAnalyticsTable] = useState(new Array<ISpaceUtilisationSummary>());
    const [sortValue, setSortValue] = useState<boolean>(false);
    const [sortKey, setSortKey] = useState<string>("");
    const [buildingnodeid, setBuildingnodeid] = useState<number>(buildingNodeId);
    const [Nodelevelname, setNodelevelname] = useState(buildingnodelevelName);
    const [Buildingclasstype, setBuildingclasstype] = useState(buildingClassType);
    const [Buildingworkspacetype, setBuildingworkspacetype] = useState(buildingWorkSpaceType);
    const [BuildingPeriodType, setBuildingPeriodType] = useState<number>(spaceAnalyticsFilter.periodType != null ? spaceAnalyticsFilter.periodType : buildingPeriodType === "Day" ? 3 : buildingPeriodType === "Week" ? 0 : buildingPeriodType === "Year" ? 2 : 1);
    const [Periodstartdate, setPeriodstartdate] = useState(spaceAnalyticsFilter.startDate || monthFirstStartDate);
    const [Periodenddate, setPeriodenddate] = useState(spaceAnalyticsFilter.endDate ||monthFirstEndDate);
    const [floor, setfloor] = useState(buildingFloor);
    const [Metabookable, setMetabookable] = useState(0);
    const [submit, setSubmit] = useState(0);
    const arrValue = [buildingOptions, buildingFloor, buildingClassType, buildingWorkSpaceType, spaceAnalyticsFilter ? getPeriodTypeString(spaceAnalyticsFilter.periodType != null ? spaceAnalyticsFilter.periodType : 1) : buildingPeriodType, startDate];
    const options = Helper.getFloorsByBuildingId(buildingnodeid).filter((val: any) => val.Node_Name === floortype);

    const updateDimensions = () =>
    {
        setWidth(window.innerWidth);
    }

    useEffect(() =>
    {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    /* Redux state change changes breadcrumb chip */
    useEffect(() =>
    {
        breadcrumbSearchData(searchData, arrIcon, arrValue);
    }, [onelensSpaceAnalyticsFilterCriteria, onelensDateBuilding, lightModeTheme])
    const firstRun = React.useRef(true);
    useEffect(() =>
    {
        if (firstRun.current)
        {
            firstRun.current = false;
            return;
        }

        if (submit === 0)
        {
            dashboardCall_();
        } else
        {
            dashboardCall();
        }
    }, [sortValue])

    /* Default API calls & dispatch */
    useEffect(() =>
    {

        defaultHeaderCall();
        dashboardCall_();
        breadcrumbSearchData(searchData, arrIcon, arrValue);

        dispatch_({
            type: UPDATE_MAIN_PAGE_TITLE,
            mainPageTitle: `${labels.HublabelSpaceAnalyticsOverview}`,
        });
        dispatch_({
            type: ONE_LENSE_DATE_BULDING,
            payload: {
                floor: options.length === 0 ? "" : options[0].Node_Id,
                periodstartDate: DateTime.fromJSDate(new Date(startdate)).toISO(),
                periodEndDate: DateTime.fromJSDate(new Date(enddate)).toISO(),
                nodeLevelName: groupby,
                buildingNodeId: parseInt(NodeID),
                metaBookable: +metabookable,
                periodtype_: BuildingPeriodType
            },
        });
    }, []);

    /* first route Header section API call */
    const defaultHeaderCall = () =>
    {
        apis.getSpaceAnalyticsHeader(parseInt(NodeID), worktype, workspaceTypeDefault, BuildingPeriodType, spaceAnalyticsFilter.startDate, spaceAnalyticsFilter.endDate, defaultNodeLevel, defaultAny).then((res) =>
        {
            setSpaceAnalyticsResultHeader(res.data[0])
        });
        apis.getHeaderKpiTargetSpaceUtilization(buildingid, classSapceCall(worktype)).then((res) =>
        {
            setSpaceKpiTarget(res.data[0]);
        })
        apis.getHeaderKpiTargetCapacityUtilization(buildingid, classCapacityCall(worktype)).then((res) =>
        {
            setCapaityKpiTarget(res.data[0])
        })

        /* Default breadcrumb chip filter values */
        dispatch_({
            type: ONELENS_SPACEANALYTICS_FILTER, payload: {
                buildingOptions: builidngroutename,
                buildingFloor: floortype,
                buildingWorkSpaceType: workspaceType,
                buildingClassType: worktype,
                buildingPeriodType: onelensSpaceAnalyticsFilterCriteria.buildingPeriodType || "Month"
            }
        });
    }

    /* Dashboard datatable API call */
    const dashboardCall_ = () =>
    {
        spaceUtilisationSummaryService
            .getSpaceUtilisationSummaries(buildingid, groupby, worktype, workspaceType, BuildingPeriodType, Periodstartdate, Periodenddate, options.length === 0 ? defaultAny : options[0].Node_Id, +metabookable, sortKey, sortValue)
            .then((res) =>
            {
                setisLoading(false);
                setspaceAnalyticsTable(res);
            }).catch((err) =>
            {
                setisLoading(false);
            });
    }


    const dashboardCall = () =>
    {
        spaceUtilisationSummaryService
            .getSpaceUtilisationSummaries(buildingnodeid, Nodelevelname, Buildingclasstype, Buildingworkspacetype, BuildingPeriodType, DateHelper.fromIsoOrDateTime(Periodstartdate), DateHelper.fromIsoOrDateTime(Periodenddate), floor, Metabookable, sortKey, sortValue)
            .then((res) =>
            {
                setisLoading(false);
                setspaceAnalyticsTable(res);
            }).catch((err) =>
            {
                setisLoading(false);
            });
    }

    /* Toggle window form submit props call */
    const searchCriteriaSubmit = (object: any) =>
    {
        setBuildingnodeid(object.buildingNodeId);
        setNodelevelname(object.nodeLevelName);
        setBuildingclasstype(object.filterData.buildingClassType);
        setBuildingworkspacetype(object.filterData.buildingWorkSpaceType);
        setBuildingPeriodType(periodTypeCall(object.filterData.buildingPeriodType));
        setPeriodstartdate(object.periodstartDate);
        setPeriodenddate(object.periodEndDate);
        setfloor(object.floor);
        setMetabookable(object.metaBookable);
        setSubmit(1);
        setSortKey("");
        setSortValue(false);
        
        const getPeriodType = object.filterData.buildingPeriodType === "Day" ? 3 : object.filterData.buildingPeriodType === "Week" ? 0 : object.filterData.buildingPeriodType === "Year" ? 2 : 1
        const startDate = DateHelper.fromIsoOrJsDate(object.periodstartDate).setZoneByNode(object.buildingNodeId).startOf('day');
        const endDate = startDate.plusPeriods(getPeriodType);

        spaceUtilisationSummaryService
            .getSpaceUtilisationSummaries(
                object.buildingNodeId,
                object.nodeLevelName,
                object.filterData.buildingClassType,
                object.filterData.buildingWorkSpaceType,
                periodTypeCall(object.filterData.buildingPeriodType),
                startDate,
                endDate,
                object.floor,
                object.metaBookable,
                "",
                false
            )
            .then((res) =>
            {
                const response = res.map((val: any) =>
                {
                    if (object.filterData.buildingWorkSpaceType !== constTotal)
                    {
                    }
                    return { ...val, spaceCount: 100 }
                });
                setspaceAnalyticsTable(response);
            }).catch((err) => console.log("err", err));

        /* Selected building not equal to 'All' API call */
        if (object.buildingNodeId !== 1)
        {
            apis
                .getSpaceAnalyticsHeader(
                    object.buildingNodeId,
                    object.filterData.buildingClassType,
                    object.filterData.buildingWorkSpaceType,
                    periodTypeCall(object.filterData.buildingPeriodType),
                    startDate,
                    endDate,
                    object.nodeLevelName,
                    object.floor
                )
                .then((response) =>
                {
                    setSpaceAnalyticsResultHeader(response.data[0]);
                })
                .catch((err) => console.log("err", err));

            apis
                .getHeaderKpiTargetSpaceUtilization(object.buildingNodeId, classSapceCall(object.filterData.buildingClassType))
                .then((res) =>
                {
                    setSpaceKpiTarget(res.data[0]);
                });

            apis
                .getHeaderKpiTargetCapacityUtilization(object.buildingNodeId, classCapacityCall(object.filterData.buildingClassType))
                .then((res) =>
                {
                    setCapaityKpiTarget(res.data[0]);
                });
        }
    };

    /* Breadcrumb chip call */
    const breadcrumbSearchData = (searchData: string | any[], arrIcon: any[], arrValue: any) =>
    {
        let arr: any = [];
        for (let i = 0; i <= searchData.length - 1; i++)
        {
            if (searchData[i] !== "workSpace")
            {
                if (i <= 4)
                {
                    if (
                        onelensSpaceAnalyticsFilterCriteria != null &&
                        onelensSpaceAnalyticsFilterCriteria != undefined
                    )
                    {
                        arr.push({
                            src: `/images/Sidebar_Icons/${lightModeTheme ? "Light_theme" : "Dark_Theme"
                                }/${arrIcon[i]}.svg`,
                            value:
                                arrValue[i] ?? "NO DATA",
                        });
                    }
                } else if (onelensDateBuilding != null &&
                    onelensDateBuilding != undefined)
                {
                    var date_ = onelensDateBuilding.periodstartDate;
                    const startDate = DateTime.fromISO(date_).toFormat('dd/MM/yy');
                    arr.push({
                        src: `/images/Sidebar_Icons/${lightModeTheme ? "Light_theme" : "Dark_Theme"
                            }/Calendar.svg`,
                        value: startDate ?? "NO DATA",
                    });
                }
            } else
            {
                arr.push({
                    src: `/images/Sidebar_Icons/${lightModeTheme ? "Light_theme" : "Dark_Theme"
                        }/${arrIcon[i]}.svg`,
                    value:
                        arrValue[i] ===
                            "Total"
                            ? "Any"
                            : arrValue[i] ??
                            "NO DATA",
                });
            }
        }
        setGetbreadcrumbSearchData(arr);
    }

    /* Toggle window open/close */
    const editSearchCriteria = () =>
    {
        setopenDrawer(!openDrawer);
    };

    /* Toggle window open/close props */
    const toggleFilterCriteraDrawer = () =>
    {
        setopenDrawer(!openDrawer)
    }

    /* Toggle window clear form props */
    const clearFormclicked = () =>
    {
        dashboardCall_();
        breadcrumbSearchData(searchData, arrIcon, arrValue);
        defaultHeaderCall();
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const sorting = (e: string) =>
    {
        setSortValue(!sortValue);
        setSortKey(e);
    }

    const sortClassCall = (val: string, para: any) =>
    {
        let classcolor = "sorthover fa fa-sort ai-center";
        if (sortKey === val && para)
        {
            classcolor = "-sort-desc fa fa-sort-desc ai-center";
        } else if (sortKey === val && !para)
        {
            classcolor = "-sort-asc fa fa-sort-asc ai-center";
        }
        else if (sortKey === val && !para)
        {
            classcolor = "sorthover fa fa-sort ai-center"
        }
        return classcolor;
    }


    return (
        <>
            <div className="page-height-exct-header">
            <div className="rightPanel-main-content">
                <div>
                    <div className="space-box-cont">
                        {/* Breadcrumb chip search filter */}
                        <div className="left-space-box-cont flex-row-bredcrumb">
                            <IbssbreadcrumbLeft value={getbreadcrumbSearchData} />
                        </div>

                        <div className="right-space-box-cont">
                            {/* Toggle Window Button*/}
                            {!openDrawer ? (
                                <IbssButtonRedo
                                    variant="contained"
                                    sx={{whiteSpace:'nowrap'}}
                                    onClick={() => editSearchCriteria()}
                                >
                                    {labels.HubButtonEditsearchcriteria}
                                </IbssButtonRedo>
                            ) : (
                                ""
                            )}
                        </div>

                        {/* Toggle Window */}
                        {openDrawer ? (
                            <SearchCriteria
                                open={openDrawer}
                                toggleDrawer={toggleFilterCriteraDrawer}
                                lightModeTheme={true}
                                searchCriteriaSubmit={searchCriteriaSubmit}
                                object={Object}
                                clearFormClicked={clearFormclicked}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="space-box-cont">
                                    <div className="left-space-box-cont flex-row-bredcrumb">
                                        <div className="search-results-title mb-16">
                                            {labels.HublabelResults}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={1}>
                        {/* Header Total Space Card */}
                        <Grid item xs={4}>
                            <div className="d-flex h-100">
                                <SpaceAnalyticsLeftHeader
                                    spaceAnalyticsTable={spaceAnalyticsTable}
                                />
                            </div>
                        </Grid>
                        {/* Header Right Section Cards*/}
                        <Grid item xs={8}>
                            <div className="d-flex">
                                {buildingNodeId !== 1 ? (
                                    <SpaceAnalyticsRightHeader
                                        CurrentSpaceValue={
                                            spaceAnalyticsResultHeader?.Period_Current_Space_Value
                                        }
                                        CurrentCapacityValue={
                                            spaceAnalyticsResultHeader?.Period_Current_Capacity_Value
                                        }
                                        CapacityKPITarget={capacityKpiTarget?.Parameter_Value}
                                        SpaceKPITarget={spaceKpiTarget?.Parameter_Value}
                                    />
                                ) : null}
                            </div>
                        </Grid>
                    </Grid>
                </div>

                {/* Datatable labels*/}
                <div className="search-results-cont search-results-cont--list mt-16 vh-card-100 space-analytics-grid">
                    <Box sx={{ flexGrow: 1, height: "100%" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item sx={{ boxShadow: "none" }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        className="xscroll border-bottom-1 space-between grid-header-fix"
                                    >
                                        {width < 990 ? null : <Grid item xs={1}></Grid>}
                                        <Grid item xs={1}>
                                            <h4 className="text-ui-md">{labels.HubLabelName}</h4>
                                        </Grid>
                                        {width < 990 ? null : (
                                            <Grid item xs={1}>
                                                <h4 className="text-ui-md">
                                                    {labels.HublabelNoofSpaces}
                                                </h4>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={1}
                                            className={sortClassCall(
                                                "Period_Current_Space_Value",
                                                sortValue
                                            )}
                                            onClick={() =>
                                            {
                                                sorting("Period_Current_Space_Value");
                                            }}
                                        >
                                            <h4 className="text-ui-md">
                                                {labels.HublabelSpaceUtilization}
                                            </h4>
                                        </Grid>
                                        {width < 1000 ? null : (
                                            <Grid item xs={1}>
                                                <h4 className="text-ui-md">{labels.HublabelChange}</h4>
                                            </Grid>
                                        )}
                                        {width < 1100 ? null : (
                                            <Grid
                                                item
                                                xs={1}
                                                className={sortClassCall(
                                                    "Period_Current_Max_Occ_Value",
                                                    sortValue
                                                )}
                                                onClick={() =>
                                                {
                                                    sorting("Period_Current_Max_Occ_Value");
                                                }}
                                            >
                                                <h4 className="text-ui-md">
                                                    {labels.HublabelMaxOccupancy}
                                                </h4>
                                            </Grid>
                                        )}
                                        {width < 1120 ? null : (
                                            <Grid
                                                item
                                                xs={1}
                                                className={sortClassCall(
                                                    "Period_Current_Avg_Occ_Value",
                                                    sortValue
                                                )}
                                                onClick={() =>
                                                {
                                                    sorting("Period_Current_Avg_Occ_Value");
                                                }}
                                            >
                                                <h4 className="text-ui-md">
                                                    {labels.HublabelAvgOccupancy}
                                                </h4>
                                            </Grid>
                                        )}
                                        {width < 1242 ? null : (
                                            <Grid item xs={1}>
                                                <h4 className="text-ui-md">
                                                    {labels.HublabelAvailableCapacity}
                                                </h4>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={2}
                                            className={sortClassCall(
                                                "Period_Current_Capacity_Value",
                                                sortValue
                                            )}
                                            onClick={() =>
                                            {
                                                sorting("Period_Current_Capacity_Value");
                                            }}
                                        >
                                            <h4 className="text-ui-md">
                                                {labels.HublabelCapacityUtilization}
                                            </h4>
                                        </Grid>
                                        {width < 1014 ? null : (
                                            <Grid item xs={1}>
                                                <h4 className="text-ui-md">{labels.HublabelChange}</h4>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Item>
                            </Grid>
                            {isLoading && <Spinner />}

                            {/* Datatable Response */}
                            <Grid
                                item
                                xs={12}
                                className="space-analytics-grid-top justify-content-center text-align-center"
                            >
                                <SpaceAnalyticsTableDash
                                    spaceAnalytics={spaceAnalyticsTable}
                                    occupanyDiff={spaceDiff(spaceAnalyticsResultHeader?.Period_Current_Space_Value, spaceKpiTarget?.Parameter_Value)}
                                    occupancyVal={spaceKpiTarget?.Parameter_Value}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria
    };
};

export default connect(mapStateToProps)(ViewSpaceUtilisation);
