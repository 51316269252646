import React, { Component } from 'react'
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { appContext } from '../../../../AppContext';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IFilterOptionsNumber } from './EditAdvancedSpace.tsx';


export default class PropertiesAndThresholds extends Component<IPropertiesAndThresholds> 
{
  private get labels() { return appContext().labels; }
 

  render(): JSX.Element
  {
    const { spaceStatus, sensorTypes,selectedSensor ,onSensorChange ,bookable,selectedBookableSettingOptionError,signTypes,occTypes,onBookableChange,occType,onOccTypeChange,thresholdsMin,onThresholdsMinsChange,signType,onDigitalSignChange} = this.props;
    
    return (
        <Box mt={2} className="tablePanel"sx={{'& > :not(style)': { my: 1},}}>
        <Box mt={2} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'} sx={{'& > :not(style)': { mt: 1},}}>
                <Box className="icon-bg-dark" mr={1} p={1.5}>
                    <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                        {Icons.SettingIcon}
                    </IbssSvgIcon>
                </Box>
                <Typography variant="h6" sx={{fontWeight:'regular'}}>{this.labels.HubLabelPropertiesDescription}</Typography>
            </Box>
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
            <IbssInputDropDown 
                options={spaceStatus}
                id="Space Class"
                value={bookable != null ? bookable.toString() : ''}
                error={selectedBookableSettingOptionError}
                inputLabel={this.labels.funcBookableSetting_S}
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onBookableChange(event)}
            />
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
            <IbssTextField 
                value={thresholdsMin} 
                fullWidth
                label={this.labels.HubLabelSignoff_Message}
                variant="outlined" 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onThresholdsMinsChange(event)}
            />
            <IbssInputDropDown 
                options={signTypes}
                id="Sign Type"
                value={signType != null ? signType.toString() : ''}
                inputLabel={this.labels.HubLabelDigitalSignType}
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onDigitalSignChange(event)}
            />
        </Box>
        <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
            <IbssInputDropDown 
                options={occTypes}
                id="Occ Type"
                value={occType != null ? occType.toString() : ''}
                inputLabel={this.labels.HubLabelOccupancyType}
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onOccTypeChange(event)}
            />
            <IbssInputDropDown 
                options={sensorTypes}
                id="sensor  Type"
                value={selectedSensor != null ? selectedSensor.toString() : ''}
                inputLabel="Sensor Type"
                fullWidth
                onChange={(event:SelectChangeEvent<string>) => onSensorChange(event)}
            />
        </Box>
    </Box>
    )
  }
}

interface IPropertiesAndThresholds 
{
    onBookableChange: (event: SelectChangeEvent<string>) => void; 
    bookable: number | null;
    occType:  number | null;
    onOccTypeChange: (event: SelectChangeEvent<string>) => void; 
    thresholdsMin: number,
    onThresholdsMinsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    signType:  number | null;
    onDigitalSignChange: (event: SelectChangeEvent<string>) => void;
    spaceStatus: IFilterOptionsNumber[];
    occTypes: IFilterOptionsNumber[];
    signTypes: IFilterOptionsNumber[];
    sensorTypes: IFilterOptionsNumber[];
    selectedSensor: number | null;
    selectedBookableSettingOptionError: boolean;
    onSensorChange: (event: SelectChangeEvent<string>) => void;
}