import React, { ChangeEvent, Component } from 'react'
import { appContext } from "../../../AppContext";
import IbssAutocomplete from '../../Inputs/AutoComplete/IbssAutocomplete';
import { TextField } from '@mui/material';
import UserPicker, { IUser } from '../../Inputs/UserPicker/UserPicker';
import Guid from '../../../Common/Guid';

export default class EmployeeTabView extends Component<IProps> {

    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        const { employeeName, error, onChange, onEmployeeChange } = this.props;

        return (
            <>
                <div className="mt-1">
                    <div className="form-group mt-3">
                        <label className="py-1">{this.labels.HubLabelEnterNameOrEmailOfEmployee}</label>
                        <UserPicker
                            searchText={employeeName}
                            onChange={text => onChange(text)}
                            onUserChange={employee => onEmployeeChange(employee)}
                        />
                        <div className="text-danger">{error}</div>
                    </div>
                </div>
            </>
        )
    }
}

export interface IProps
{
    employeeName: string;
    error: string;
    onChange: (employeeSearchText: string) => Promise<void>;
    onEmployeeChange: (user: IUser | null) => Promise<void>;
}
