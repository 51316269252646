import React, { Component } from 'react'
import { Box, Grid, SelectChangeEvent, TextField, Typography } from "@mui/material";
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { appContext } from '../../../../AppContext';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import MediaSelector, { IFile } from '../../../../Components/DialogLaunchers/MediaSelector/MediaSelector';
import { IFileType, IFilterOptions, IFilterOptionsNumber } from './EditAdvancedSpace.tsx';


export default class SpaceConfiguration extends Component<ISpaceConfigurationProps> 
{
  private get labels() { return appContext().labels; }

  render(): JSX.Element
  {
    const {onSpaceNameLabelChange,spaceNameError,spaceIdError,selectedBuildingOptionError,bookingPolicyOptionError,imageSelectedChange,onActiveSpaceChange, spaceNameLabel, buildingOptions, isCreate, selectedBuildingOption,onBuildingChange,onSpaceIdChange, newSpaceId,buildingName,onUploadImageChange, spaceId,activeSpace,spaceName,onSpaceNameChange,onSpaceCustomInfoChange,spaceCustomInfo, onOrderPolicyChange,allBookingPolicies,selectedPolicy,images,imageUrl} = this.props;
    return (
        <Grid xs={4} pr={2}>
            <Box className="tablePanel">
            {isCreate ?
                <Box sx={{'& > :not(style)': { my: 1}}}>
                    <IbssInputDropDown 
                        value={selectedBuildingOption}
                        inputLabel={this.labels.HubLabelBuilding}
                        required
                        id="Building"
                        error={selectedBuildingOptionError}
                        options={buildingOptions}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onBuildingChange(e)}
                    />
                    <IbssTextField 
                        fullWidth
                        required
                        label={this.labels.HubLabelSpaceId}
                        value={newSpaceId}
                        error={spaceIdError}
                        variant="outlined" 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>)=> onSpaceIdChange(event)}
                    />
                </Box>
                :                
                <Box sx={{'& > :not(style)': { my: 2}}}>
                    <Typography variant="h5">{this.labels.HubLabelSpaceConfig}</Typography>
                    <Typography variant="body1" className="ui-text-light">{this.labels.HubLabelSpaceConfiguration_Message}</Typography>
                    <Box mt={2} alignItems={'center'} className="card-secondary d-flex justify-content-between">
                        <Box display={'flex'} alignItems={'center'}>
                            <IbssSvgIcon sx={{color: (theme) => theme.palette.text.secondary }} fontSize='large'>
                                {Icons.SpaceBuildingIcon}
                            </IbssSvgIcon>
                            <Typography ml={2} variant="body1">{this.labels.HubLabelBuilding}</Typography>
                        </Box>
                        <Typography variant="body1">{buildingName}</Typography>
                    </Box>
                    <Box mt={2} alignItems={'center'} className="card-secondary d-flex justify-content-between">
                        <Box display={'flex'} alignItems={'center'}>
                            <IbssSvgIcon sx={{color: (theme) => theme.palette.text.secondary }} fontSize='large'>
                                {Icons.KeyIcon}
                            </IbssSvgIcon>
                            <Typography ml={2} variant="body1">{this.labels.HubLabelSpaceId}</Typography>
                        </Box>
                        <Typography variant="body1">{spaceId}</Typography>
                    </Box>
                </Box>
            }
                <Box className="d-flex justify-content-between" alignItems={'center'} mt={3}>
                <Typography variant="body2">{this.labels.HubLabelActiveSpace}</Typography>
                <IbssFormControl>
                    <IbssSwitchLabel 
                        checked={activeSpace} 
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onActiveSpaceChange(e)} 
                    />
                </IbssFormControl>
                </Box>
                <Box sx={{'& > :not(style)': { my:1},}}>
                    <IbssTextField 
                        fullWidth
                        label={this.labels.HubLabelName}
                        error={spaceNameError}
                        value={spaceName}
                        variant="outlined" 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>)=> onSpaceNameChange(event)}
                    />
                    <IbssTextField 
                        fullWidth
                        required
                        label={this.labels.HubLabelSpaceNameLabel}
                        value={spaceNameLabel}
                        variant="outlined" 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>)=> onSpaceNameLabelChange(event)}
                    />
                    <IbssTextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSpaceCustomInfoChange(event)}
                        label={this.labels.HubLabelSpaceCustomInformation}
                        value={spaceCustomInfo}
                        multiline
                        fullWidth
                        rows={4}
                    />
                    <IbssInputDropDown
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onOrderPolicyChange(e)}
                        value={selectedPolicy}
                        inputLabel={this.labels.HubBookingPolicyTitle}
                        error={bookingPolicyOptionError}
                        id="Booking Policy"
                        disabled={selectedBuildingOption == "" && isCreate}
                        options={allBookingPolicies}
                    />
                    <Box>
                        <Typography variant="body1">
                            {this.labels.HubLabelSpaceImage}
                        </Typography>
                        <MediaSelector
                            options={images.map(x => {return{name: x.name, url:x.path}})}
                            imageSelected={(file: IFileType) => imageSelectedChange(file)}
                            uploadFile={(filename: string, file: FormData, processAsMap: boolean) => onUploadImageChange(filename, file, processAsMap)}
                            defaultSelectedImage={imageUrl}
                        />
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
  }
}

interface ISpaceConfigurationProps 
{
    buildingName: string;
    spaceId: string;
    activeSpace: boolean;
    spaceName: string;
    onSpaceNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSpaceCustomInfoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    spaceCustomInfo: string;
    onOrderPolicyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedPolicy: string;
    allBookingPolicies: IFilterOptions[];
    images: IFile[];
    onActiveSpaceChange:  (event: React.ChangeEvent<HTMLInputElement>) => void;
    onUploadImageChange: (filename: string, file: FormData, processAsMap: boolean) => Promise<void>;
    imageUrl: string;
    getImages: () => void;
    buildingOptions: IFilterOptionsNumber[];
    selectedBuildingOption: string;
    onBuildingChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    newSpaceId: string;
    onSpaceIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    spaceNameLabel: string;
    onSpaceNameLabelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isCreate: boolean;
    imageSelectedChange: (file: IFileType) => void;
    spaceNameError: boolean;
    selectedBuildingOptionError: boolean;
    bookingPolicyOptionError: boolean;
    spaceIdError: boolean;
}